import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductType } from '../../models/product-type.models';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Language, LanguageTableContent, PriceTableContent, Product } from '../../models/edit-book.models';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { LicenseTypeService } from '../../services/license-type.service';
import { LanguageService } from 'src/app/languages/services/language.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { EditorialService } from 'src/app/editorials/services/editorial.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { CountryInfoWithCurrency } from '../../models/country-info-with-currency.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { Category } from '../../models/category-product.models';
import { CategoryProductService } from '../../services/category-product.service';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {

  readonly editProductObserver = {
    next: (data: CommonResponse<any>) => this.editProductNext(data),
    error: (errorStatusCode: number) => this.editProductError(errorStatusCode),
    complete: () => this.editProductComplete()  
    
  }

  //Default options for form selects
  validAvailableLanguages: Language[];
  validAvailableCountries: CountryInfoWithCurrency[];
  validAvailableLicenses: any;
  productId: number;
  CategoriesData: Category[];
  productType: ProductType[] = ProductType.data;
  dataProduct: Product;
  validDataProduct: any;

  //Input book data
  productForm: FormGroup;
  languageForm: FormGroup;
  priceForm: FormGroup;
  selectedElement: any;
  selectedLanguage: any;
  selectedPrice: any;
  selectedElementPrice: any;
  formSubmitted: boolean = false;

  constructor(private _formBuilder: FormBuilder,
    private _licenseTypeService: LicenseTypeService,
    private _languageService: LanguageService,
    private _editorialService: EditorialService,
    private _snackbarService: SnackbarService,
    private _activatedRoute: ActivatedRoute,
    private _pageLoadingService: PageLoadingService,
    private _category_productService : CategoryProductService,
    private _router: Router) {

    this.productId = this._activatedRoute.snapshot.params['id'];
    this.productForm = this._formBuilder.group({
      productCode: [''],
      interactiveCode: [''],
      availability: new FormControl(['']),
      weight: [''],
      licenseType: [''],
      selectedCategories: [[]],
      isPreview: [false],
      content: [''],
      contentSource: [''],
      coverImage: [''],
      coverImageSource: [''],
      type: [''],
      uniqueMagazineCode: [''],
      productId: [''],
    });

    this.priceForm = this._formBuilder.group({
      'position': new FormControl(''),
      'amount': ['', [Validators.required, this.numericValidator]],
      'taxes': ['', [Validators.required, this.numericValidator]],
      'country': ['', [Validators.required]]
    });
   
    this.languageForm = this._formBuilder.group({
      'index': new FormControl(''),
      'language': new FormControl(''),
      'title': new FormControl(''),
      'shortDescription': new FormControl(''),
      'resume': new FormControl('')
    });

    this.initializeAvailableLicenses();
    this.initializeAvailableLanguages();
    this.initializeAvailableCountries();

    this._editorialService.editProduct(this.productId)
      .subscribe(this.editProductObserver);
  }

  // Define una función de validación personalizada para verificar si el valor es numérico
  numericValidator = (control: FormControl) => {
    const value = control.value;
    // Verifica si el valor es numérico
    if (isNaN(value) || value < 0) {
      // Si no es numérico o es negativo, devuelve un objeto con el error
      return { numeric: true };
    }
    // Si es numérico, devuelve nulo (indicando que no hay error)
    return null;
  };

  initializeAvailableLanguages() {
    this._languageService.getAllLanguages().subscribe(data => {
      if (ResponseHelper.responseDontHaveErrors(data)) {
        this.validAvailableLanguages = (data as CommonResponse<any>).data;
      }
      else {
        console.log('Error bringing languages: ' + data);
      }
    });
  }

  initializeAvailableLicenses() {
    this._licenseTypeService.getAllServices().subscribe(data => {
      if (ResponseHelper.responseDontHaveErrors(data)) {
        this.validAvailableLicenses = (data as CommonResponse<any>).data;
      }
      else {
        console.log('Error bringing licenses: ' + data);
      }
    });
  }

  initializeAvailableCountries() {
    this._editorialService.getEditorialCountries().subscribe(data => {
      if (ResponseHelper.responseDontHaveErrors(data)) {
        this.validAvailableCountries = (data as CommonResponse<any>).data;
      }
      else {
        console.log('Error bringing licenses: ' + data);
      }
    });
  }

  languageDisplayedColumns: string[] = ['position', 'language', 'title', 'shortDesc', 'actions'];
  languageDataSource = new MatTableDataSource<LanguageTableContent>([]);
  isUpdatingLanguage: boolean = false;
  previousLanguage: any;

  priceDisplayedColumns: string[] = ['position', 'country', 'taxes', 'amount', 'totalCost', 'actions'];
  priceDataSource = new MatTableDataSource<PriceTableContent>([]);
  isUpdatingPrice: boolean = false;
  previousPriceCountry: any;



  @ViewChild('languageTableSort')
  languageTableSort: MatSort;

  @ViewChild('languageTable')
  languageTable: MatTable<LanguageTableContent>;

  @ViewChild('priceTableSort')
  priceTableSort: MatSort;

  @ViewChild('priceTable')
  priceTable: MatTable<PriceTableContent>;

  /*
  Language and amount fileds of respective tables data sources are complex objects.
  By this was necessary to implement sorting accesor for those:
  return the complex required property as simple value (sortingLanguageAccessor and sortinPriceAccessor).
  These functions for sorting are configured in ngOninit in sortingDataAccessor of each data source.
  */
  sortingLanguageAccessor = (item: { [key: string]: any }, property: string) => {
    switch (property) {
      case 'language': return (item as LanguageTableContent).language.name;
      default: return item[property];
    }
  };

  sortinPriceAccessor = (item: { [key: string]: any }, property: string) => {
    switch (property) {
      case 'country': return (item as PriceTableContent).country.countryInfo.name;
      default: return item[property];
    }
  };

  ngOnInit(): void {
    this.getAllCategories();
    this.languageDataSource.sortingDataAccessor = this.sortingLanguageAccessor;
    this.priceDataSource.sortingDataAccessor = this.sortinPriceAccessor;
  }

  ngAfterViewInit() {
    this.languageTableSort.disableClear = true;
    this.priceTableSort.disableClear = true;
  }

  onFileChange(event: any, formControlName: string) {
    if (event.target?.files?.length > 0) {
      const file = event.target?.files[0];
      const data: { [key: string]: File } = {};
      data[`${formControlName}Source`] = file;
      this.productForm.patchValue(data);

      const fileNameLabel = document.getElementById(`${formControlName}FileName`) as HTMLInputElement;
      fileNameLabel?.setAttribute('value', event.target?.files[0].name);
    }
  }

  get f() {
    return this.productForm.controls;
  }

  hasCategorySelection(): boolean {
    return this.productForm.value.selectedCategories.length > 0;
  }

  
  getAllCategories() {
    this._category_productService.getCategory()
      .subscribe({
        next: (response) => {
          // Filtra las categorías que tengan isDelete igual a 0
          this.CategoriesData = (response.data as any[]).filter(category => category.isDelete === 0);
        },
        error: (error) => {
          console.error('Error al hacer la petición:', error);
        }
      });
  }

  editProductNext(data: CommonResponse<any>) {

    this.validDataProduct = data.data;
    this.dataProduct = this.validDataProduct;
    const categoriesString = this.dataProduct.product.field_data[0].data;

    // Convertir la cadena de categorías en un array
    const categoriesArray = JSON.parse(categoriesString);
  
    // Asignar el array de categorías al campo del formulario
    this.productForm.controls['selectedCategories'].setValue(categoriesArray);

    // console.log('data', currentCategoryId);

    this.productForm.controls['availability'].setValue(this.dataProduct.availability);
    this.productForm.controls['selectedCategories'].setValue(categoriesArray);
    this.productForm.controls['interactiveCode'].setValue(this.dataProduct.product.interactiveCode);
    this.productForm.controls['licenseType'].setValue(this.dataProduct.licenseType);
    this.productForm.controls['productId'].setValue(this.dataProduct.product.productId);
    this.productForm.controls['weight'].setValue(this.dataProduct.product.weight);

    if (this.dataProduct.product.magazine != null) {
      this.productForm.controls['uniqueMagazineCode'].setValue(this.dataProduct.product.magazine.uniqueMagazineCode);
      this.productForm.controls['productCode'].setValue(this.dataProduct.product.magazine.issn);
      this.productForm.controls['type'].setValue("Magazine")
    }
    else {
      this.productForm.controls['uniqueMagazineCode'].setValue(null);
      this.productForm.controls['productCode'].setValue(this.dataProduct.product.book.isbn);
      this.productForm.controls['type'].setValue("Book")
    }

    this.languageDataSource.data = this.dataProduct.product.product_general_info;

    this._pageLoadingService.showLoadingGif();
    /**
     * Registered into timeout for wait until the validCountries are charged from DB.
     */
    setTimeout(() => {
      this.priceDataSource.data = this.dataProduct.prices.map((item, index) => {
        const country = this.validAvailableCountries.find(country => country.countryInfo.countryCode == item.country.countryCode);

        const priceFormDbAsPriceElement : PriceTableContent = {
          index: index,
          amount: item.amount,
          taxes: item.taxes,
          country: country
        };

        return priceFormDbAsPriceElement;
      });

      this._pageLoadingService.hideLoadingGif();
    }, 1000)

    this.languageDataSource.data = this.dataProduct.product.product_general_info.map((item, index) => ({ ...item, index: index + 1 }));
    this.priceDataSource.data = this.dataProduct.prices.map((item, index) => ({ ...item, index: index + 1 }))
  }

  editProductError(errorStatusCode: number) {

    if (errorStatusCode == 404) {
      this._snackbarService.openStandardSnackBar("editProductError404");
    }
    else {
      this._snackbarService.openStandardSnackBar("editProductError");
    }
  }

  editProductComplete() {
    //aquí va el gif
  }

  addLanguageData() {

    const isValidlanguageForm = this.validateFormData(this.languageForm);;

    if (!isValidlanguageForm)
      return;

    if (!this.isLanguageFilled(this.languageForm?.get('language')?.value.languageCode)) {
      const addLanguageFunction = () => {
        const newLanguageData: LanguageTableContent = {
          index: this.languageDataSource.data.length + 1,
          language: this.languageForm?.get('language')?.value,
          resume: this.languageForm?.get('resume')?.value,
          shortDescription: this.languageForm?.get('shortDescription')?.value,
          title: this.languageForm?.get('title')?.value
        };
        console.log(this.languageDataSource.data.length,);

        this.languageDataSource.data.push(newLanguageData);
        this.languageTable.renderRows();
      };

      this.executeTableAction(this.languageDataSource, this.languageTableSort, addLanguageFunction);
    }
    else {
      this._snackbarService.openStandardSnackBar('addLanguageData', 'Ok');
    }
  }

  editLanguageData() {

    const selectedLanguageName = this.languageForm.value.language;
    const updatedElement = {
      index: this.selectedElement.index,
      shortDescription: this.languageForm.value.shortDescription,
      title: this.languageForm.value.title,
      resume: this.languageForm.value.resume,
      language: selectedLanguageName,
    };

    const index = this.languageDataSource.data.findIndex((element: any) => element.index === this.selectedElement.index);
    this.languageDataSource.data[index] = updatedElement;
    this.languageDataSource._updateChangeSubscription(); // Notifica a la tabla que se ha actualizado la fuente de datos
    this.selectedElement = null;
    this.languageForm.reset(); // Limpia los valores del formulario después de la actualización
    this.isUpdatingLanguage = false;
  }

  editElementLanguage(row: any) {
    this.isUpdatingLanguage = true;
    this.selectedElement = row;
    this.selectedLanguage = this.validAvailableLanguages.find(x => x.languageCode === this.selectedElement.language.languageCode) || '';
    this.languageForm.patchValue({
      // Inicializa los campos del formulario con los valores de la fila seleccionada
      index: row.index,
      language: this.selectedLanguage,
      title: row.title,
      shortDescription: row.shortDescription,
      resume: row.resume,
    });
    this.languageForm.controls['language'].setValue(this.selectedLanguage);
  }

  editElementPrice(row: any) {
    this.isUpdatingPrice = true;
    this.selectedElementPrice = row;
    this.selectedPrice = this.validAvailableCountries.find(x => x.countryInfo?.countryCode === this.selectedElementPrice?.country.countryInfo.countryCode) || '';
    this.priceForm.patchValue({
      // Inicializa los campos del formulario con los valores de la fila seleccionada
      index: row.index,
      country: this.selectedPrice,
      amount: row.amount,
      taxes: row.taxes,
    });

    this.priceForm.controls['country'].setValue(this.selectedPrice);
  }

  editPriceData1() {
    this.formSubmitted = true;
    
    const isValidPriceForm = this.validateFormData(this.priceForm);

    if (!isValidPriceForm){
      return;
    }

    const selectedPriceCountry = this.priceForm.value;

    const index = this.priceDataSource.data.findIndex((element: any) => element.index === this.selectedElementPrice.index);
    let elementToUpdate = {...this.priceDataSource.data[index]};
    elementToUpdate.index = this.selectedElementPrice.index;
    elementToUpdate.amount = this.priceForm.value.amount;
    elementToUpdate.taxes = this.priceForm.value.taxes;
    elementToUpdate.country = this.validAvailableCountries.find(x => x.countryInfo.countryCode == selectedPriceCountry.country.countryInfo.countryCode);

    this.priceDataSource.data[index] = elementToUpdate;
    this.priceDataSource._updateChangeSubscription(); // Notifica a la tabla que se ha actualizado la fuente de datos
    this.selectedElementPrice = null;
    this.priceForm.reset(); // Limpia los valores del formulario después de la actualización
    this.isUpdatingPrice = false;
    this.onSubmit();
    this.formSubmitted = false;
  }

  removeLanguageData(position: number) {
    const removeLanguageFunction = () => {
      const indexToDelete = this.languageDataSource.data.findIndex(x => x.index == position);
      this.languageDataSource.data.splice(indexToDelete, 1);
      this.languageTable.renderRows();
    };

    this.executeTableAction(this.languageDataSource, this.languageTableSort, removeLanguageFunction);
  }

  setLanguageFormToEdit(position: number) {
    this.isUpdatingLanguage = true;
    // console.log(position);

    this.removeInvalidStyleFromForm(this.languageForm);

    const indexToUpdate = this.languageDataSource.data.findIndex(x => x.index == position);
    const elementToUpdate = this.languageDataSource.data[indexToUpdate];
    this.languageForm?.get('index')?.setValue(elementToUpdate.index);
    this.languageForm?.get('language')?.setValue(elementToUpdate.language);
    this.languageForm?.get('title')?.setValue(elementToUpdate.title);
    this.languageForm?.get('shortDescription')?.setValue(elementToUpdate.shortDescription);
    this.languageForm?.get('resume')?.setValue(elementToUpdate.resume);

    this.previousLanguage = elementToUpdate.language.languageCode;
  }

  discardLanguageEdit() {
    this.isUpdatingLanguage = false;

    this.languageForm?.get('index')?.setValue('');
    this.languageForm?.get('language')?.setValue('default');
    this.languageForm?.get('title')?.setValue('');
    this.languageForm?.get('shortDescription')?.setValue('');
    this.languageForm?.get('resume')?.setValue('');

    this.removeInvalidStyleFromForm(this.languageForm);
  }

  isLanguageFilled(languageCode: string) {
    return this.languageDataSource.data.some(x => {
      return x.language.languageCode === languageCode;
    });
  }

  addPriceData() {
    // Marcar el control de impuestos como tocado para activar la validación
    // this.priceForm.get('amount')?.markAsTouched();
    this.formSubmitted = true;

    const isValidPriceForm = this.validateFormData(this.priceForm);

    if (!isValidPriceForm)
      return;

    if (!this.isPriceCountryFilled(this.priceForm?.get('country')?.value.countryInfo.countryCode)) {
      const addPriceFunction = () => {
        const newPriceData: PriceTableContent = {
          index: this.priceDataSource.data.length,
          amount: this.priceForm?.get('amount')?.value,
          taxes: this.priceForm?.get('taxes')?.value,
          country: this.priceForm?.get('country')?.value
        };

        this.priceDataSource.data.push(newPriceData);
        this.priceTable.renderRows();
      };

      this.executeTableAction(this.priceDataSource, this.priceTableSort, addPriceFunction);
      this.onSubmit();
    }
    else {
      this._snackbarService.openStandardSnackBar('addPriceData', 'Ok');
    }
  }

  editPriceData() {

    const isValidPriceForm = this.validateFormData(this.priceForm);

    if (!isValidPriceForm)
      return;

    const selectedCountryCode = this.priceForm?.get('country')?.value.countryInfo.countryCode;

    const isValidEdit = !this.isPriceCountryFilled(selectedCountryCode) ||
      this.previousPriceCountry.countryInfo.countryCode == selectedCountryCode;

    if (isValidEdit) {
      const editPriceFunction = () => {
        const indexToUpdate = this.priceForm?.get('position')?.value;

        this.priceDataSource.data[indexToUpdate].amount = this.priceForm?.get('amount')?.value;
        this.priceDataSource.data[indexToUpdate].taxes = this.priceForm?.get('taxes')?.value;
        this.priceDataSource.data[indexToUpdate].country = this.priceForm?.get('country')?.value;

        this.discardPriceEdit();
      };

      this.executeTableAction(this.priceDataSource, this.priceTableSort, editPriceFunction);
    }
    else {
      this._snackbarService.openStandardSnackBar('editPriceData', 'Ok');
    }
  }

  setPriceFormToEdit(position: number) {
    this.isUpdatingPrice = true;
    this.removeInvalidStyleFromForm(this.priceForm);

    const indexToUpdate = this.priceDataSource.data.findIndex(x => x.index == position);
    const elementToUpdate = this.priceDataSource.data[indexToUpdate];
    this.priceForm?.get('position')?.setValue(elementToUpdate.index);
    this.priceForm?.get('country')?.setValue(elementToUpdate.country);
    this.priceForm?.get('amount')?.setValue(elementToUpdate.amount);
    this.priceForm?.get('taxes')?.setValue(elementToUpdate.taxes);

    this.previousPriceCountry = elementToUpdate.country;
  }

  discardPriceEdit() {
    this.isUpdatingPrice = false;

    this.priceForm?.get('position')?.setValue('');
    this.priceForm?.get('country')?.setValue('default');
    this.priceForm?.get('amount')?.setValue('');
    this.priceForm?.get('taxes')?.setValue('');

    this.removeInvalidStyleFromForm(this.priceForm);
    
    this.priceForm.markAsUntouched(); // Restablecer el estado de 'touched' del formulario
    this.formSubmitted = false;
  }

  removePriceData(position: number) {
    const removePriceFunction = () => {
      const indexToDelete = this.priceDataSource.data.findIndex(x => x.index == position);
      this.priceDataSource.data.splice(indexToDelete, 1);
      this.priceTable.renderRows();
    };

    this.executeTableAction(this.priceDataSource, this.priceTableSort, removePriceFunction);
  }

  isPriceCountryFilled(countryCode: string) {
    return this.priceDataSource.data.some(x => {
      return x.country.countryInfo.countryCode === countryCode;
    });
  }

  getTotalCost(element: any): number {
    return parseInt(element.amount) + parseInt(element.taxes);
  }

  private executeTableAction(dataSource: MatTableDataSource<any>, dataSort: MatSort, action: any) {
    /*Reason: The table wasn't refreshing when data source changing. It was caused by sort. By this reson we should
      disable the sort before update the table info, and before enable the sort again
    */
    this.disableDataSort(dataSource);

    action();

    this.enableDataSort(dataSource, dataSort);
  }

  private enableDataSort(dataSource: MatTableDataSource<any>, dataSort: MatSort) {
    dataSource.sort = dataSort;
  }

  private disableDataSort(dataSource: MatTableDataSource<any>) {
    dataSource.sort = null;
  }

  validateFormData(formGroup: FormGroup) {
    Object.entries(formGroup.controls).forEach(([key, value]) => {

      const controlElement = document.querySelector(`[formControlName=${key}]`);

      if ((value as AbstractControl).status == 'INVALID')
        controlElement?.classList.add('is-invalid');
      else
        controlElement?.classList.remove('is-invalid');
    });

    // console.log(formGroup);

    return formGroup.status == 'VALID';
  }

  removeInvalidStyleFromForm(formGroup: FormGroup) {
    Object.entries(formGroup.controls).forEach(([key, value]) => {

      const controlElement = document.querySelector(`[formControlName=${key}]`);
      controlElement?.classList.remove('is-invalid');
    });
  }

  generateFormdataForRequest() {
    let requestFormData = new FormData();
    let bookLanguages: { title: string; shortDescription: string; resume: string; languageCode: any; }[] = [];
    let bookPrices: { amount: number; taxes: number; country: any; }[] = [];

    this.languageDataSource.data.forEach(lang => {
      bookLanguages.push({
        title: lang.title,
        shortDescription: lang.shortDescription,
        resume: lang.resume,
        languageCode: lang.language.languageCode
      });
    });
    // console.log(this.priceDataSource.data);

    this.priceDataSource.data.forEach(amount => {
      if (amount.country.countryInfo != undefined) {
        bookPrices.push({
          amount: amount.amount,
          taxes: amount.taxes,
          country: amount.country.countryInfo.countryCode
        });
      } else {
        bookPrices.push({
          amount: amount.amount,
          taxes: amount.taxes,
          country: amount.country.countryCode
        });
      }
    });

    requestFormData.append('coverImage', this.productForm.get('coverImageSource')?.value);
    requestFormData.append('content', this.productForm.get('contentSource')?.value);
    requestFormData.set('interactiveCode', this.productForm.get('interactiveCode')?.value);
    requestFormData.set('productCode', this.productForm.get('productCode')?.value);
    requestFormData.set('productId', this.productForm.get('productId')?.value);
    requestFormData.set('editorialId', `${this.dataProduct.editorialId}`);
    requestFormData.set('availability', this.productForm.get('availability')?.value);
    requestFormData.set('weight', this.productForm.get('weight')?.value);
    requestFormData.set('licenseType', this.productForm.get('licenseType')?.value);
    requestFormData.set('selectedCategories', this.productForm.get('selectedCategories')?.value);
    requestFormData.set('isPreview', this.productForm.get('isPreview')?.value);
    requestFormData.set('uniqueMagazineCode', this.productForm.get('uniqueMagazineCode')?.value);
    requestFormData.set('type', this.productForm.get('type')?.value);
    requestFormData.set('languages', JSON.stringify(bookLanguages));
    requestFormData.set('prices', JSON.stringify(bookPrices));

    return requestFormData;
  }

  validateLanguagesData() {
    let isSomeLanguageNotContained = false;

    this.validAvailableLanguages.forEach((validElement: any) => {
      this.languageDataSource.data.some((sourceElement) => {
        if (validElement.languageCode != sourceElement.language.languageCode)
          isSomeLanguageNotContained = true;
      });
    });

    return isSomeLanguageNotContained;
  }

  onSubmit() {

    this._pageLoadingService.showLoadingGif();

    if (!this.validateFormData(this.productForm)) {
      if (!this.validateLanguagesData()) {
        this._snackbarService.openStandardSnackBar('onSubmitInvalidFormData');
      }

      this._pageLoadingService.hideLoadingGif();
      return;
    }
    else {
      this._editorialService.updateProduct(this.generateFormdataForRequest()).subscribe((data) => {
        this._pageLoadingService.hideLoadingGif();
        this._snackbarService.openStandardSnackBar('onSubmitSuccessfullyUpdated', 'Ok');
      },
        (error) => {
          this._pageLoadingService.hideLoadingGif();
          this._snackbarService.openStandardSnackBar('onSubmitError', 'Ok');
        });
    }
  }

}
