import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment as env} from 'src/environments/environment';
import { CommonResponse } from '../../../shared/models/reponse.model';
import { LoginService } from '../../services/logIn.service';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { ValidateRoutePermissions } from '../../models/route-permission.auth';
import { LoggedUser } from '../../models/user.auth';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { ShopCartService } from 'src/app/books/services/shop-cart.service';
import { CountryService } from 'src/app/countries/services/country.service';
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements AfterViewInit {
  email: string = "";
  password: string = "";
  twofa_code: string = "";
  showErrorMessages = false;
  countryName: string = '';
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  private readonly twoFaLoginObserver = {
    next: (data: CommonResponse<any>) => this.twoFaLoginNext(data),
    error: (error: CommonResponse<any>) => this.twoFaLoginError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };
  constructor(private _loginService: LoginService,
    private _router: Router,
    private _snackbarService: SnackbarService,
    private _countryService: CountryService,
    private cdr: ChangeDetectorRef,
    private _pageLoadingService: PageLoadingService,
    private _shopCartService: ShopCartService) { }
  ngOnInit() {
    const storedCountryName = localStorage.getItem('countryName');
    if (storedCountryName !== null) {
      this.countryName = storedCountryName;
      this._countryService.getCountryName$().subscribe(countryName => {
        this.countryName = countryName;
        this.cdr.detectChanges(); // Marcar la vista como actualizada
      });
    } else {
      this._countryService.getCountryName$().subscribe(countryName => {
        this.countryName = countryName;
        this.cdr.detectChanges(); // Marcar la vista como actualizada
      });
    }
  }
  ngAfterViewInit(){ }
  isInvalidEmail() {
    let regExValidator = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$");
    return !regExValidator.test(this.email);
  }
  isInvalidPassword() {
    return this.password.length < env.password_min_length;
  }
  isInvalidCode() {
    return this.twofa_code.length < env.code_length;
  }
  twoFaLoginNext(data: CommonResponse<any>) {
    this._router.navigate(ValidateRoutePermissions.getDefaultUrlForUser(data.data.user as LoggedUser) || []);
  }
  twoFaLoginError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar(error.message);
  }
  twoFaLogin() {
    if (!this.isInvalidEmail() && !this.isInvalidPassword()) {
      this._pageLoadingService.showLoadingGif();
      this._loginService.twoFaLogin(this.email, this.password, this.twofa_code, this._shopCartService.getCartProductsIdsFromLocal())
        .subscribe(this.twoFaLoginObserver);
    }
    else {
      this.showErrorMessages = true;
    }
  }
}