<body>
    <div class="container row">
        <div class="containerLeft col-6 md-6 justify-content-between">
            <div id="loginContainer">
                <div class="logo">
                    <ng-container *ngIf=" countryName === ''">
                        <img src="" class="mb-3 col-9" style="margin-top: 12rem !important;">
                    </ng-container>
                    <ng-container *ngIf=" countryName !== 'Colombia' && countryName !== ''">
                        <img src="../../../../assets/img/png/gridmark-login.png" class="gridmark">
                        <img src="../../../../assets/img/png/watermark_gridmark.png" class="watermarkGridmark">
                        <img src="../../../../assets/img/png/watermark_gridmark.png" class="watermarki3book2">
                    </ng-container>
                    <ng-container *ngIf=" countryName === 'Colombia'">
                        <img src="../../../../assets/img/png/logo-new.png" class="i3book">
                        <img src="../../../../assets/img/png/watermark_i3book.png" class="watermarki3book">
                        <img src="../../../../assets/img/png/watermark_i3book.png" class="watermarki3book2">
                    </ng-container>
                </div>
                <div class="text-image">
                    <p class="imageCaption">
                        {{'auth.login.intro_text' | translate}}
                    </p>
                </div>
            </div>
        </div>
        <div class="containerRight col-6 col-md-6 background-login-form py-2 bg-white">
            <div class="login">
                <p class="text-white titleLogin">{{'auth.register.welcome' | translate}}</p>
                <div class="subtitle">
                    <p class="fw-lighter text-white">{{'auth.login.enter_data' | translate}}</p>
                </div>
                <form [formGroup]="registerForm">
                    <div class="inputLogin text-center pr-2 pb-2">
                        <input class="inputslogin" type="text" name="name" id="name" formControlName="name"
                            placeholder="{{'auth.register.name' | translate}}">
                        <input class="inputslogin" type="text" name="email" id="email" formControlName="email"
                            placeholder="{{'auth.register.email' | translate}}">
                        <input class="inputslogin" type="password" name="password" formControlName="password"
                            id="password" placeholder="{{'auth.register.password' | translate}}">
                        <div class="form-floating">
                            <select class="inputslogin" id="floatingSelect" formControlName="countryCode">
                                <option *ngFor="let country of countries" [value]="country.countryCode">
                                    {{getCountryNameByLanguage(country)}}</option>
                            </select>
                        </div>
                        <div class="form-floating">
                            <select class="inputslogin" id="floatingSelect" formControlName="defaultLanguageCode">
                                <option *ngFor="let language of languages" [value]="language.languageCode">
                                    {{language.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="hintText">
                        <p class="">
                            <a [routerLink]="['/login']" class="text-reset">{{'auth.register.have_aready_account' |
                                translate}}</a>
                        </p>

                    </div>
                    <div class="loginButtons">
                        <button class="sendButton" type="submit" (click)="create()">{{'auth.login.send' |
                            translate}}</button>
                        <button class="closeButton" type="button" [routerLink]="['/login']">{{'auth.register.back' |
                            translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</body>