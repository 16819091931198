<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">

    <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet'>

    <title>Document</title>
</head>
<body>
    <div class="main">
        <div class="row">
            <!-- Header -->
            <div class="row headerListBook">
                <div class="col-12 p-2">
                    <b class="titleListBook">{{'my_books.all_prodcts_mb' | translate}}</b>
                </div>
                <div class="col-7"></div>
                <br>
            </div>
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <p class="descriptionViewListBook">
                                {{'my_books.message_my_books_mb' | translate}}
                            </p>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                      <ng-container *ngIf="dataSource.data.length > 0 else elseTemplate">

                        <div class="mat-elevation-z8">
                            <table mat-table [dataSource]="dataSource">

                              <!-- Position Column -->
                              <ng-container matColumnDef="imgName">
                                <th mat-header-cell *matHeaderCellDef class="sizeThead text-center"><b>{{'my_books.image_mb' | translate}}</b></th>
                                <!-- ../../../../assets/img/jpg/{{element.product.productCoverImageName}} -->
                                <td mat-cell *matCellDef="let element" class="imgColSize"><img class="imgBook" [src]="api_path_img+element.productCoverImageName" alt="vacio"> </td>
                              </ng-container>

                              <!-- Name Column -->
                              <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'my_books.book_code_mb' | translate}}</b></th>
                                <td mat-cell *matCellDef="let element" class="colSize"><b class="sizeMaxText">{{element.id}}</b></td>
                              </ng-container>

                              <!-- Weight Column -->
                              <ng-container matColumnDef="code">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'create_product.interactive_product_crp' | translate}} </b></th>
                                <td mat-cell *matCellDef="let element" class="colSize"><b class="sizeMaxText">{{element.interactiveCode}}</b></td>
                              </ng-container>

                              <!-- Symbol Column -->
                              <ng-container matColumnDef="title">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'create_product.title_crp' | translate}}</b></th>
                                <td mat-cell *matCellDef="let element" class="shortcolSize"><b class="sizeMaxText">{{getBookTitleByLanguage(element.id)}}</b></td>
                              </ng-container>

                              <ng-container matColumnDef="resume">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'create_product.summary_crp' | translate}}</b></th>
                                <td mat-cell *matCellDef="let element" class="resumecolSize"><p class="textDecriptionSize">{{getBookResumeByLanguage(element.id)}}</p></td>
                              </ng-container>

                              <ng-container matColumnDef="multilanguageText">
                                <th mat-header-cell *matHeaderCellDef class="text-center"><b>{{'create_product.multi_language_text_crp' | translate}}</b></th>
                                <td mat-cell *matCellDef="let element" class="colSize"><p *ngFor="let langName of getMultiLanguageText(element.id)"><b class="sizeMaxText">{{langName}}</b></p></td>
                              </ng-container>

                              <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="sizeThead text-center"><b>{{'create_product.actions_crp' | translate}}</b></th>
                                <td mat-cell *matCellDef="let element" class="colSize">
                                  <button class="btn" [routerLink]="['/book/edit-product', element.id]"><i class="bi bi-pencil-fill colorIconAction"></i></button>
                                  <button class="btn" (click)="deleteProduct(element.id)"><i class="bi bi-trash-fill colorIconAction"></i></button>
                                </td>
                              </ng-container>

                              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                              <!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
                              <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell no-data-centered" colspan="5">{{'create_product.not_products_crp' | translate}}</td>
                              </tr>

                              <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell no-data-centered" colspan="5">{{'create_product.empty_crp' | translate}}</td>
                              </tr>

                            </table>
                            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                            <!--TO DO: pagination bootstrap

                                <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-end">
                                  <li class="page-item">
                                    <a class="page-link" href="#" aria-label="Previous">
                                      <span aria-hidden="true">&laquo;</span>
                                    </a>
                                  </li>
                                  <li class="page-item active"><a class="page-link" href="/book/view-archive-books">1</a></li>
                                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                                  <li class="page-item">
                                    <a class="page-link" href="#" aria-label="Next">
                                      <span aria-hidden="true">&raquo;</span>
                                    </a>
                                  </li>
                                </ul>
                            </nav>    -->
                       </div>
                      </ng-container>
                       <ng-template #elseTemplate>
                        <br><br><br>
                        <div class="row text-center">
                          <div class="col">
                            <h2>{{"store.not_products" | translate}}</h2>
                            <br>
                            <button class="sendAddBook" [routerLink]="['/book/shop']">{{'store.go_to_store' | translate}}</button>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
</html>

