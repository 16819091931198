import { Component, OnInit } from '@angular/core';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { ShopCartProduct } from '../../models/shop-cart-product.model';
import { ShopCartService } from '../../services/shop-cart.service';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { LoginService } from 'src/app/auth/services/logIn.service';
import { ShopProductsService } from '../../services/shop-products.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-shop-cart',
  templateUrl: './shop-cart.component.html',
  styleUrls: ['./shop-cart.component.scss']
})
export class ShopCartComponent implements OnInit {
  distance: number;
  shippingPrice: number;
  shopCartBooks: ShopCartProduct[] = [];
  countries: string[] = ["Colombia", "España", "Irlanda", "Japon"]
  currencySymbol: string = "$";
  private readonly getCartProductsObserver = {
    next: (data: CommonResponse<any>) => this.getCartProductsNext(data),
    error: (error: CommonResponse<any>) => this.getCartProductsError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };
  private readonly getShopCountryObserver = {
    next: (data: CommonResponse<any>) => this.getCountryData(data),
    error: (error: CommonResponse<any>) => this.getCountryDataError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };
  constructor(private _snackBarService: SnackbarService,
    private _shopProductsService: ShopProductsService,
    private _shopCartService: ShopCartService,
    private _pageLoadingService: PageLoadingService,
    private _loginService: LoginService,
    private translateService: TranslateService) {
    }
  ngOnInit(): void {
    this._pageLoadingService.showLoadingGif();
    this._shopCartService.getCartProducts()
      .subscribe(this.getCartProductsObserver);
    this._shopProductsService.getCountryData()
      .subscribe(this.getShopCountryObserver)
  }
  isLoggedUser() {
    return this._loginService.isLogged();
  }
  getCartProductsNext(data: CommonResponse<any>) {
    this.shopCartBooks = data.data;
    this.shopCartBooks.map(this._shopCartService.setShopcartProductQuantity);
    this.currencySymbol = this.shopCartBooks[0].price.currencySymbol;
  }
  getCountryData(data: CommonResponse<any>) {
    let storedCountryName = localStorage.getItem('countryName');
    const userCountry = this.countries.find((country) => storedCountryName)
    // if(storedCountryName == 'Colombia' || storedCountryName == 'España') {
    //   this.distance = data.data[0].distance;
    //   console.log('DISTANCIA --', this.distance)
    // }
  }
  getTranslatedText(key: string): string {
    let storedCountryName = localStorage.getItem('countryName');
    if (storedCountryName && (storedCountryName.toLowerCase() === 'españa')) {
      return this.translateService.instant(key + '_spain');
    } else {
      return this.translateService.instant(key);
    }
  }
  getCountryDataError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    let storedCountryName = localStorage.getItem('countryName');
    if(error.statusCode == 404) {
      if (storedCountryName && (storedCountryName.toLowerCase() === 'españa')) {
        this._snackBarService.openStandardSnackBar('getCartProductsError404_spain');
      } else {
        this._snackBarService.openStandardSnackBar('getCartProductsError404');
      }
    }
    else {
      if (storedCountryName && (storedCountryName.toLowerCase() === 'españa')) {
        this._snackBarService.openStandardSnackBar('getCartProductsError_spain');
      } else {
        this._snackBarService.openStandardSnackBar('getCartProductsError');
      }
    }
  }
  getCartProductsError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    let storedCountryName = localStorage.getItem('countryName');
    if(error.statusCode == 404) {
      if (storedCountryName && (storedCountryName.toLowerCase() === 'españa')) {
        this._snackBarService.openStandardSnackBar('getCartProductsError404_spain');
      } else {
        this._snackBarService.openStandardSnackBar('getCartProductsError404');
      }
    }
    else {
      if (storedCountryName && (storedCountryName.toLowerCase() === 'españa')) {
        this._snackBarService.openStandardSnackBar('getCartProductsError_spain');
      } else {
        this._snackBarService.openStandardSnackBar('getCartProductsError');
      }
    }
  }
  deleteProductEventHandler(event: any) {
    this._shopCartService.deleteProductFromCart(event);
    const indexToDelete = this.shopCartBooks.findIndex(x => x.product.productId == event);
    this.shopCartBooks.splice(indexToDelete, 1);
  }
  getShippingCost() {
    let shippingPrice = 0
      this.shopCartBooks.forEach((p) => {
        shippingPrice += Number(p.product.weight * p.quantity) * this.distance ;
      });
    return shippingPrice;
  }
  getTotalTaxes() {
    let totalTaxes = 0;
    this.shopCartBooks.forEach((p) => {
      totalTaxes += Number(p.price.taxes) * p.quantity;
    });
    return totalTaxes;
  }
  getTotalCostWithoutTaxes() {
    let totalCost = 0;
    this.shopCartBooks.forEach((p) => {
      totalCost += Number(p.price.amount) * p.quantity;
    });
    return totalCost;
  }
  getTotalCostWithTaxes() {
    return this.getTotalTaxes() + this.getTotalCostWithoutTaxes();
  }
  setCurrencySymbol() {
    this.currencySymbol = this.shopCartBooks[0].price.currencySymbol;
  }
}