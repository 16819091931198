import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CountryService } from 'src/app/countries/services/country.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  countryName: string = '';

  constructor(private _countryService: CountryService, private cdr: ChangeDetectorRef, private translateService: TranslateService) { }

  ngOnInit(): void {
    const storedCountryName = localStorage.getItem('countryName');
    
    if (storedCountryName !== null) {
      this.countryName = storedCountryName;
      this._countryService.getCountryName$().subscribe(countryName => {
        this.countryName = countryName;
        this.cdr.detectChanges(); // Marcar la vista como actualizada
      });
      // this.cdr.detectChanges(); // Marcar la vista como actualizada
    } else {
      this._countryService.getCountryName$().subscribe(countryName => {
        this.countryName = countryName;
        this.cdr.detectChanges(); // Marcar la vista como actualizada
      });
    }
  }

  getTranslatedText(key: string): string {
    let storedCountryName = localStorage.getItem('countryName');
    if (storedCountryName && (storedCountryName.toLowerCase() === 'españa')) {
      return this.translateService.instant(key + '_spain');
    } else {
      return this.translateService.instant(key);
    }
  }

}
