<div class="main">
    <div class="row">
        <!-- Header -->
        <div class="row headerListBook">
            <div class="col-8 p-2">
                <b class="titleListBook"> {{'footer.data_processing_ft' | translate}} </b>
            </div>
            <div class="col-7"></div>
            <br>
        </div>
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <div class="textDescriptionContainer">
                            <div class="LinksDiv">
                                <div class="line"></div>
                                    <div class="list" (click)="scrollToTitle ('information')"> 
                                        <div class="circle"></div>
                                        {{'Terms.information' | translate}}
                                    </div>
                                    <div class="list" (click)="scrollToTitle ('license')">
                                        <div class="circle"></div>
                                        {{'Terms.licesnse' | translate}} 
                                    </div>
                                    <div class="list" (click)="scrollToTitle ('unauthorizedUse')"> 
                                        <div class="circle"></div>
                                        {{'Terms.como' | translate}} 
                                    </div>
                                    <div class="list" (click)="scrollToTitle ('como')"> 
                                        <div class="circle"></div>
                                        {{'Terms.impuestos' | translate}}  
                                    </div>
                                    <div class="list" (click)="scrollToTitle ('impuestos')"> 
                                        <div class="circle"></div>
                                        {{'Terms.obligaciones' | translate}}  
                                    </div>
                                    <div class="list" (click)="scrollToTitle ('obligaciones')"> 
                                        <div class="circle"></div>
                                        {{'Terms.pago' | translate}}  
                                    </div>
                                    <div class="list" (click)="scrollToTitle ('pago')"> 
                                        <div class="circle"></div>
                                        {{'Terms.envio' | translate}}  
                                    </div>
                                    <div class="list" (click)="scrollToTitle ('envio')"> 
                                        <div class="circle"></div>
                                        {{'Terms.gastos' | translate}}  
                                    </div>
                                    <div class="list" (click)="scrollToTitle ('gastos')"> 
                                        <div class="circle"></div>
                                        {{'Terms.derechos' | translate}}  
                                    </div>
                                    <div class="list" (click)="scrollToTitle ('derechos')"> 
                                        <div class="circle"></div>
                                        {{'Terms.legislacion' | translate}}  
                                    </div>
                            </div>
                            <p class="descriptionViewListBook">
                                <p class="textDecriptionSize">
                                    <strong id="information">{{'Terms.information' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.information_text' | translate}}
                                    <br><br>

                                    <strong id="license">{{'Terms.licesnse' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.licesnse_text' | translate}}
                                    <br><br>

                                    <strong id="unauthorizedUse">{{'Terms.unauthorized_use' | translate}}O</strong>
                                    <br><br>
                                    {{'Terms.unauthorized_use_text' | translate}}
                                    <br><br>

                                    <strong id="como">{{'Terms.como' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.como_text' | translate}}
                                    <br><br>

                                    <strong id="impuestos">{{'Terms.impuestos' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.impuestos_text' | translate}}
                                    <br><br>

                                    <strong id="obligaciones">{{'Terms.obligaciones' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.obligaciones_text' | translate}}
                                    <br><br>

                                    <strong id="pago">{{'Terms.pago' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.pago_text' | translate}}
                                    <br><br>

                                    <strong id="envio">{{'Terms.envio' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.envio_text' | translate}}
                                    <br><br>

                                    <strong id="gastos">{{'Terms.gastos' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.gastos_text' | translate}}
                                    <br><br>

                                    <strong id="derechos">{{'Terms.derechos' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.derechos_text' | translate}}
                                    <br><br>

                                    <strong id="legislacion">{{'Terms.legislacion' | translate}}</strong>
                                    <br><br>
                                    {{'Terms.legislacion_text' | translate}}
                                    <br><br>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="mat-elevation-z8">
                        <ng-container matColumnDef="resume">
                            <th mat-header-cell *matHeaderCellDef class="text-center">
                                <b>{{'create_product.summary_crp' | translate}}</b></th>
                            <td mat-cell *matCellDef="let element" class="resumecolSize">
                            </td>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>