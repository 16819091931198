import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersRoutingModule } from './users-routing.module';
import { ListUsersComponent } from './components/list-users/list-users.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/matherial/matherial.module';
import { RegisterUsersComponent } from './components/register-users/register-users.component';
import { CreateUsersComponent } from './components/create-users/create-users.component';
import { TranslateModule } from '@ngx-translate/core';
import { EditUsersComponent } from './components/edit-users/edit-users.component';
import { CreateCategoriesComponent } from './components/create-categories/create-categories.component';
import { EditCategoriesComponent } from './components/edit-categories/edit-categories.component';



@NgModule({
  declarations: [
    ListUsersComponent,
    RegisterUsersComponent,
    CreateUsersComponent,
    EditUsersComponent,
    CreateCategoriesComponent,
    EditCategoriesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    UsersRoutingModule,
    TranslateModule
  ]
})
export class UsersModule { }
