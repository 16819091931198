import { Component, OnInit, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { EditorialService } from 'src/app/editorials/services/editorial.service';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { environment as env } from 'src/environments/environment';
import { LoginService } from 'src/app/auth/services/logIn.service';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { ViewMyBooksProduct } from '../../models/view-my-books-book.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-view-my-books-component',
  templateUrl: './view-my-books-component.component.html',
  styleUrls: ['./view-my-books-component.component.scss']
})

export class ViewMyBooksComponentComponent implements OnInit {

  displayedColumns: string[] = ['imgName', 'id', 'code', 'title', 'resume', 'multilanguageText'];
  dataSource = new MatTableDataSource<ViewMyBooksProduct>();
  api_path_img = `${env['images_url_base']}`;

  idUser = 0;
  permissions: string[];
  isAdmin = false;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  private readonly getUserProductsObserver = {
    next: (data: CommonResponse<any>) => this.getUserProductsNext(data),
    error: (error: CommonResponse<any>) => this.getUserProductsError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };


  private readonly deleteUserProductsObserver = {
    next: (data: CommonResponse<any>) => this.deleteUserProductsNext(data),
    error: (error: CommonResponse<any>) => this.deleteUserProductsError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  constructor(private _editorialService: EditorialService,
    private _loginService: LoginService,
    private _pageLoadingService: PageLoadingService,
    private _translate: TranslateService,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private _renderer: Renderer2) { }

  ngOnInit(): void {
    this._pageLoadingService.showLoadingGif();
    const user = this._loginService.getLoggedUser();
    if (user != null) {
      this.idUser = user.userId;
      this.permissions = user.permissions;
      this.havePermissions(this.permissions);
    }

    this.getproducts();
  }

  getUserProductsNext(data: CommonResponse<any>) {
    if (ResponseHelper.responseDontHaveErrors(data)) {
      this.dataSource.data = (data.data as CommonResponse<any>).data;
      setTimeout(() => {
        this.initializePaginator();
        this._pageLoadingService.hideLoadingGif();
      }, 500);
    }
    else {
      // console.log('Error bringing licenses: ' + data);
      this._pageLoadingService.hideLoadingGif();
    }
  }

  getUserProductsError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    if (error.statusCode == 404) {
      this.dataSource.data = [];
      this._snackbarService.openStandardSnackBar('getUserProductsError404');
    }
    // else {
    //   this._snackbarService.openStandardSnackBar('getUserProductsError');
    // }
  }

  getproducts() {
    this._editorialService.getproducts(2, 1, this.idUser).subscribe(this.getUserProductsObserver);
  }

  initializePaginator() {
    this.dataSource.paginator = this.paginator;
  }

  havePermissions(permissions: any) {
    const havePermissions = permissions.find(function (permission: any) {
      return permission === 'update_book';
    });

    if (havePermissions == 'update_book') {
      this.isAdmin = true;
      this.displayedColumns.push('actions');
    }
  }

  getBookTitleByLanguage(id: number) {
    const product = this.dataSource.data.find(x => x.id == id);
    if (product != null && product != undefined) {
      return product.product_general_info.find(x => x.language.languageIsoCode == this._translate.currentLang)?.title;
    }
    else {
      return "empty title";
    }
  }

  getBookResumeByLanguage(id: number) {
    const product = this.dataSource.data.find(x => x.id == id);

    if (product != null && product != undefined) {
      return product.product_general_info.find(x => x.language.languageIsoCode == this._translate.currentLang)?.resume;
    }
    else {
      return "empty resume";
    }
  }

  getMultiLanguageText(id: number) {
    let bookLanguages: string[] = [];
    const product = this.dataSource.data.find(x => x.id == id);

    if (product != null && product != undefined) {
      product.product_general_info.forEach(bc => {
        let langName = ";"
        const lang = this._translate.currentLang;
       
        if (lang == 'en') {
          switch (bc.language.languageIsoCode) {
            case "en":
              langName = "English";
              break;
            case "es":
              langName = "Spanish";
              break;
          }
        } else{
          switch (bc.language.languageIsoCode) {
            case "en":
              langName = "Inglés";
              break;
            case "es":
              langName = "Español";
              break;
        }
        }

        bookLanguages.push(langName);
      });
    }

    return bookLanguages;
  }

  deleteUserProductsNext(data: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar('successfullyDeletedProduct');
    this._editorialService.getproducts(2, 1, this.idUser).subscribe(this.getUserProductsObserver);
  }

  deleteUserProductsError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    if (error.statusCode == 404) {
      this._snackbarService.openStandardSnackBar('unsuccessfullyDeletedProduct');
    }
    else {
      this._snackbarService.openStandardSnackBar('errorDeletingProduct');
    }
  }

  deleteProduct(id: number) {
    const data: DialogData = {
      title: this._translate.instant('delete_product.title'),
      message: this._translate.instant('delete_product.confirmation_question'),
      userInteractResult: false
    };

    const confirmPurchaseDialogRef = this._dialog.open(ConfirmationModalComponent, {
      width: '250px',
      data: data
    });

    const dialogContainer = document.querySelector('mat-dialog-container');
    if (dialogContainer) {
      this._renderer.setStyle(dialogContainer, 'border-top', 'solid 5px orangered', RendererStyleFlags2.Important);
    }

    confirmPurchaseDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._pageLoadingService.showLoadingGif();
        this._editorialService.deleteProduct(id).subscribe(this.deleteUserProductsObserver);
      }
      else {
        // console.log('Compra no confirmada');
      }
    });
  }
}
