import { Component } from '@angular/core'
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { Category } from '../../models/category-product.models';
import { CategoryProductService } from '../../services/category-product.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { CreateCategoriesComponent } from 'src/app/users/components/create-categories/create-categories.component';
import { EditCategoriesComponent } from 'src/app/users/components/edit-categories/edit-categories.component';



@Component({
  selector: 'app-list-category',
  templateUrl: './list-category.component.html',
  styleUrls: ['./list-category.component.scss']
})
export class ListCategoryComponent {

  private readonly getAllCategoriesObserver = {
    next: (data: CommonResponse<any>) => this.getAllCategoriesNext(data),
    error: (error: CommonResponse<any>) => this.getAllCategoriesError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  private readonly deleteCategoryObserver = {
    next: (data: CommonResponse<any>) => this.deleteCategoryNext(data),
    error: (error: CommonResponse<any>) => this.deleteCategoryError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  CategoriesData: any[] = [];
  newCategory : Category;
  constructor(private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
    private _categoryService: CategoryProductService,
    private _dialog: MatDialog) {
      this._pageLoadingService.showLoadingGif();
      
      this._categoryService.getCategory()
        .subscribe(this.getAllCategoriesObserver);
    }
    
  getAllCategoriesError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar(`deleteUserError`);
  }
  
  getAllCategoriesNext(data: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this.CategoriesData = data.data;
  }
    
  addCategory() {
    this.CategoriesData.push({ ...this.newCategory });
    this.newCategory = { name: '', isDelete: false }; 

    console.log(this.CategoriesData);
  }

  addNewCategory(){
    const confirmPurchaseDialogRef = this._dialog.open(CreateCategoriesComponent, {
      height: '65%',
      width: '50%',
    });

  }

  editCategory(id:number, name: string, status:number){
    const confirmDeleteDialogRef = this._dialog.open(EditCategoriesComponent, {
      width: '450px',
      data: { userId: id, name: name, status: status },
    });
  }

  showDeleteUserConfirmation(name: string, id: number) {
    const data: DialogData = {
      title: 'Confirmar eliminación',
      message: `¿Estás seguro de que deseas eliminar la categoria ${name}?`,
      userInteractResult: false
    };

    const confirmDeleteDialogRef = this._dialog.open(ConfirmationModalComponent, {
      width: '350px',
      data: data
    });

    confirmDeleteDialogRef.afterClosed().subscribe(result => {
      this._pageLoadingService.showLoadingGif();

      if(result) {
        this._categoryService.deleteCategory(id)
        .subscribe(this.deleteCategoryObserver);
      }

      this._pageLoadingService.hideLoadingGif();
    });
  }

  deleteCategoryError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar(`deleteUserError`);
  }
  
  deleteCategoryNext(data: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this.CategoriesData = data.data;
    window.location.reload()
  }

}