import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observer, catchError, map } from 'rxjs';
import { CookieTokenService } from 'src/app/auth/services/cookie-token.service';
import { LoginService } from 'src/app/auth/services/logIn.service';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { environment as env } from 'src/environments/environment';
import { LocalShopCartProduct } from '../models/local-shopcart-product.model';
import { ShopCartProduct } from '../models/shop-cart-product.model';

@Injectable({
  providedIn: 'root'
})
export class ShopCartService {

  private readonly _localStorageCartKey = 'i3bookapp_shop_cart';

  setShopcartProductQuantity = (shopCartProduct: ShopCartProduct, index: number, productsArray: ShopCartProduct[]) => {
    if(shopCartProduct.quantity == null) {
      const serializedShopCart = localStorage.getItem(this._localStorageCartKey);

      //Set quantity from storage
      if(serializedShopCart !== null) {
        let shopCartProducts = JSON.parse(serializedShopCart);
        productsArray[index].quantity = shopCartProducts[index].quantity;
      }
    }
    //Set default quantity in one (1)
    else if(shopCartProduct.quantity <= 0) {
      productsArray[index].quantity = 1;
    }
  }

  constructor(private _httpClient: HttpClient,
    private _cookieTokenService: CookieTokenService,
    private _loginService: LoginService) { }

  existProductOnCartLocal(productId: number) {
    const serializedShopCart = localStorage.getItem(this._localStorageCartKey);

    if(serializedShopCart !== null) {
      let shopCartProducts = JSON.parse(serializedShopCart);

      return (shopCartProducts as LocalShopCartProduct[]).length > 0 &&
        (shopCartProducts as LocalShopCartProduct[]).some(x => x.productId == productId);
    }

    return false;
  }

  cleanLocalShopCart() {
    localStorage.setItem(this._localStorageCartKey, '[]');
  }

  updateProductQuantityOnShopCart(productId: number, quantity: number) {
    if(this._loginService.isLogged()) {
      const headers = new HttpHeaders().set('Authorization', `${env.token_type} ${this._cookieTokenService.getCookieToken()}`);
      return this._httpClient.post(`${env.url_api}/${env.api_version}/shop/cart/product/update-quantity/${productId}/${quantity}`, null, { observe: 'response' as 'response', headers: headers})
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
      }));
    }
    else {
      const serializedShopCart = localStorage.getItem(this._localStorageCartKey);

      if(serializedShopCart !== null) {
        let shopCartProducts = JSON.parse(serializedShopCart);
        const indexToUpdate = (shopCartProducts as LocalShopCartProduct[]).findIndex(p => p.productId == productId);
        shopCartProducts[indexToUpdate].quantity = quantity;

        localStorage.setItem(this._localStorageCartKey, JSON.stringify(shopCartProducts));
      }

      return null;
    }
  }

  getCartProductsIdsFromLocal() {
    const serializedShopCart = localStorage.getItem(this._localStorageCartKey);
    let shopCartProducts = null;

    if(serializedShopCart !== null) {
      shopCartProducts = JSON.parse(serializedShopCart);
    }

    return shopCartProducts;
  }

  addProductToCart(productId: number, observer: Observer<any>, quantity: number = 1) {
    if(!this.existProductOnCartLocal(productId)) {
      if(this._loginService.isLogged()) {
        this.addProductToCartOnDatabase(productId).subscribe(observer);
      }
      else {
        const serializedShopCart = localStorage.getItem(this._localStorageCartKey);
        let shopCartProducts = [];

        if(serializedShopCart !== null) {
          shopCartProducts = JSON.parse(serializedShopCart);
        }

        const newShopCartProduct: LocalShopCartProduct = {
          productId: productId,
          quantity: quantity
        };

        shopCartProducts.push(newShopCartProduct);
        localStorage.setItem(this._localStorageCartKey, JSON.stringify(shopCartProducts));
      }
    }
  }

  deleteProductFromCart(productId: number) {
    if(this._loginService.isLogged()) {
      this.deleteProductFromCartOnDatabase(productId).subscribe();
    }

    const serializedShopCart = localStorage.getItem(this._localStorageCartKey);

    if(serializedShopCart !== null) {
      let shopCartProducts = JSON.parse(serializedShopCart);
      const indexToDelete = (shopCartProducts as LocalShopCartProduct[]).findIndex(p => p.productId == productId);
      shopCartProducts.splice(indexToDelete, 1);

      localStorage.setItem(this._localStorageCartKey, JSON.stringify(shopCartProducts));
    }
  }

  getCartProducts() {
    if(this._loginService.isLogged()) {
      return this.getUserCartProductsFromDatabase();
    }

    const serializedShopCart = localStorage.getItem(this._localStorageCartKey);
    let shopCartProducts = [];

    if(serializedShopCart !== null) {
      const deserializedShopCart = JSON.parse(serializedShopCart);
      shopCartProducts = deserializedShopCart.map((sp: LocalShopCartProduct) => sp.productId);
    }

    return this.getCartProductsFromAPI(shopCartProducts);
  }

  private addProductToCartOnDatabase(productId: number) {

    const headers = new HttpHeaders().set('Authorization', `${env.token_type} ${this._cookieTokenService.getCookieToken()}`);

    return this._httpClient.post(`${env.url_api}/${env.api_version}/shop/cart/product/${productId}`, null, { observe: 'response' as 'response', headers: headers})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  private deleteProductFromCartOnDatabase(productId: number) {

    const headers = new HttpHeaders().set('Authorization', `${env.token_type} ${this._cookieTokenService.getCookieToken()}`);

    return this._httpClient.delete(`${env.url_api}/${env.api_version}/shop/cart/product/${productId}`, { observe: 'response' as 'response', headers: headers})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  private getUserCartProductsFromDatabase() {

    const headers = new HttpHeaders().set('Authorization', `${env.token_type} ${this._cookieTokenService.getCookieToken()}`);

    return this._httpClient.get(`${env.url_api}/${env.api_version}/shop/cart/products`, { observe: 'response' as 'response', headers: headers})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  private getCartProductsFromAPI(productsIds: number[]) {

    const request = {
      productCodes: productsIds
    };

    return this._httpClient.post(`${env.url_api}/${env.api_version}/shop/cart/products`, request, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

}
