<div class="col-12">
  <ng-container>
    <form [formGroup]="productForm" enctype="multipart/form-data" novalidate class="need">
        <!-- <form [formGroup]="productForm" enctype="multipart/form-data" novalidate class="need"></form> -->
      <!-- Explicacion de como agregar un libro -->
      <div class="row">
        <div class="col-6 p-2">
          <b class="titleCreateBook">{{'update_product.update_a_product_up' | translate}}</b>
        </div>
        <div class="col-12">
            <p class="descriptionViewAddBook">
                {{'update_product.explanation' | translate}}
            </p>
        </div>
    </div>
    <br><br><br>
    <!-- Info libro -->
    <div class="row d-flex justify-content-evenly">
        <div class="col-5">
            <p class="subtitlesAddBook d-flex justify-content-center">{{'update_product.product_information_up' | translate}}</p>
            <small class="smalltitlesAddbok d-flex justify-content-center">{{'update_product.update_product_information_up' | translate}}</small>
        </div>
        <div class="col-7">
            <div class="accordion accordion-item">
                <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <!-- Información libro -->
                    <a class="accordion-button collapsed addBookButton" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne"><i class="bi bi-plus-circle-fill sizeIconAdd"></i><b class="addBookButton"> {{'create_product.add_crp' | translate}}</b></a>
                </h2>
                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                    <div class="accordion-body">
                      <div class="row" hidden>
                        <div class="col mb-2">
                          <input type="text" formControlName="type" name="type" id="type" class="inputSize form-control">
                        </div>
                      </div>
                      <div class="row" hidden>
                          <div class="col">
                              <input type="text" formControlName="interactiveCode" name="interactiveCode" id="interactiveCode" class="inputSize form-control" placeholder="{{'create_product.interactive_product_crp' | translate}}*">
                          </div>
                      </div>
                      <div class="row" hidden>
                        <div class="col">
                            <input type="text" formControlName="uniqueMagazineCode" name="uniqueMagazineCode" id="uniqueMagazineCode" class="inputSize form-control" placeholder="{{'create_product.unique_magazine_code_crp' | translate}}">
                        </div>
                    </div>
                      <div class="row" hidden>
                          <div class="col">
                              <input type="text" formControlName="productCode" name="productCode" id="productCode" class="inputSize form-control" placeholder="{{'create_product.product_code_crp' | translate}}*">
                          </div>
                      </div>
                      <div class="row" hidden>
                        <div class="col">
                            <input type="text" formControlName="productId" name="productId" id="productId" class="inputSize form-control" placeholder="{{'create_product.code_productId' | translate}}*">
                        </div>
                    </div>
                      <div class="row">
                           <div class="col"> <!-- Disponibilidad  -->
                              <input type="number" min="1" formControlName="availability" name="availability" id="availability" class="inputSize form-control" placeholder="{{'create_product.availabilty_crp' | translate}}*">
                            </div>
                      </div>
                      <div class="row">
                        <input type="number" formControlName="weight" name="weight" id="weight" class="inputSize form-control" placeholder="{{'create_product.book_weight' | translate}}">
                      </div>
                      
                      <div class="row" hidden>
                        <div class="col mb-2">
                          <mat-form-field appearance="fill"class="inputSize">
                            <mat-label>{{'create_product.licence_crp' | translate}}</mat-label>
                            <mat-select formControlName="licenseType">
                              <mat-option *ngFor="let licenseType of validAvailableLicenses" [value]="licenseType.licenseTypeId">
                                {{licenseType.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col mb-2">
                          <mat-form-field appearance="fill" class="inputSize">
                            <mat-label>{{'create_product.addCategory' | translate}}</mat-label>
                            <mat-select formControlName="selectedCategories" multiple>
                              <mat-option *ngFor="let category of CategoriesData" [value]="category.name">{{ 'category.' + category.name | translate }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      
                      <!-- Mensaje de advertencia para categorías -->
                      <div *ngIf="!hasCategorySelection()">
                        <p> {{'create_product.selectedCategoryMS' | translate}}</p>
                      </div>
                      <div class="row">
                        <div class="col">
                          <input type="text" id="contentFileName" class="inputSize form-control" placeholder="{{'create_product.interactive_content' | translate}}" disabled>
                        </div>
                        <div class="col">
                          <div id="file-body">
                            <label for="content">
                              <div class="mat-raised-button mat-button-base lg-i3book-button">
                                {{'create_product.examine_crp' | translate}}
                              </div>
                            </label>
                            <input type="file" formControlName="content" (change)="onFileChange($event, 'content')" name="content" id="content">
                          </div>
                        </div>
                        <!-- <input type="file" formControlName="content" (change)="onFileChange($event, 'content')" name="content" id="content" class="inputSize form-control" placeholder="Contenido interactivo"> -->
                      </div>
                      <div class="row">
                        <div class="col">
                          <input type="text" id="coverImageFileName" class="inputSize form-control" placeholder="{{'create_product.cover_crp' | translate}}" disabled>
                        </div>
                        <div class="col">
                          <div id="file-body">
                            <label for="coverImage">
                              <div class="mat-raised-button mat-button-base lg-i3book-button">
                                {{'create_product.examine_crp' | translate}}
                              </div>
                            </label>
                            <input  type="file" formControlName="coverImage" (change)="onFileChange($event, 'coverImage')" name="coverImage" id="coverImage">
                        </div>
                        </div>
                        <!-- <input type="file" formControlName="coverImage" (change)="onFileChange($event, 'coverImage')" name="coverImage" id="coverImage" class="inputSize form-control" placeholder="Portada"> -->
                      </div>
                    </div>
                    <button (click)="onSubmit()" class="mat-raised-button mat-button-base lg-i3book-button m-2">
                      {{'update_product.update_up' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Separador -->
    <div class="row">
        <div class="col">
            <div class="lineSeparator"></div>
        </div>
    </div>
    <hr>
    <!-- Texto multi-idioma -->
    <div class="row d-flex justify-content-evenly">
        <div class="col-5">
            <p class="subtitlesAddBook d-flex justify-content-center">{{'update_product.multi_language_text_up' | translate}}</p>
            <p class="smalltitlesAddbok d-flex justify-content-center">{{'update_product.update_product_languages_up' | translate}}</p>
        </div>
        <div class="col-7">
            <div class="row">
                <div class="accordion accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                      <a href="#" class="accordion-button collapsed addBookButton" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo"><i class="bi bi-plus-circle-fill sizeIconAdd"></i><b class="addBookButton"> {{'create_product.add_crp' | translate}}</b></a>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                        <div class="accordion-body">
                            <div class="row">
                                <table mat-table matSort #languageTableSort="matSort" #languageTable [dataSource]="languageDataSource" class="table mb-4 w-100 text-start">
                                  <!-- Position Column -->
                                  <ng-container matColumnDef="position">
                                    <th mat-header-cell *matHeaderCellDef class="sizeThead">#</th>
                                    <td mat-cell *matCellDef="let element">{{element.index}}</td>
                                  </ng-container>

                                  <!-- language Column -->
                                  <ng-container matColumnDef="language">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by language" class="sizeThead">{{'create_product.language_crp' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">{{element.language.name}}</td>
                                  </ng-container>

                                  <!-- title Column -->
                                  <ng-container matColumnDef="title">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by title" class="sizeThead">{{'create_product.title_crp' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">{{element.title}}</td>
                                  </ng-container>

                                  <!-- title Column -->
                                  <ng-container matColumnDef="shortDesc">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by shortDesc" class="sizeThead">{{'create_product.short_description_crp' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">{{element.shortDescription}}</td>
                                  </ng-container>

                                  <!-- actions Column -->
                                  <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef class="sizeThead">{{'create_product.actions_crp' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                      <!-- <button class="btn" (click)="setLanguageFormToEdit(element.index)"><i class="bi bi-pencil-fill colorIconAction"></i></button> -->
                                      <button class="btn" (click)="editElementLanguage(element)"><i class="bi bi-pencil-fill colorIconAction"></i></button>
                                      <button class="btn" (click)="removeLanguageData(element.index); onSubmit();" *ngIf="!isUpdatingLanguage"><i class="bi bi-trash-fill colorIconAction"></i></button>
                                    </td>
                                  </ng-container>

                                  <tr mat-header-row *matHeaderRowDef="languageDisplayedColumns"></tr>
                                  <tr mat-row *matRowDef="let row; columns: languageDisplayedColumns;"></tr>

                                  <!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
                                  <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell no-data-centered" colspan="5">{{'create_product.empty_crp' | translate}}</td>
                                  </tr>
                                </table>
                              </div>
                              <div [formGroup]="languageForm">
                                <div class="row">
                                    <div class="col mb-2">
                                      <mat-form-field appearance="fill" class="inputSize">
                                        <mat-label>{{'create_product.language_crp' | translate}}</mat-label>
                                        <mat-select formControlName="language">
                                          <mat-option *ngFor="let lang of validAvailableLanguages" [value]="lang">
                                            {{lang.name}}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <input type="text" matInput  formControlName="title" type="text" name="title" id="title" class="inputSize form-control" placeholder="{{'create_product.title_crp' | translate}}">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <input type="text" matInput  formControlName="shortDescription" type="text" name="short_desc" id="short_desc" class="inputSize form-control" placeholder="{{'create_product.short_description_crp' | translate}}">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <textarea formControlName="resume" name="resume" id="resume" cols="47" rows="10" class="textareaSize" placeholder="{{'create_product.summary_crp' | translate}}"></textarea>
                                    </div>
                                </div>
                                <div class="row">
                                  <div class="col">
                                    <button *ngIf="isUpdatingLanguage" (click)="editLanguageData(); onSubmit()" class="mat-raised-button mat-button-base lg-i3book-button m-2">
                                      {{'update_product.update_up' | translate}}
                                    </button>
                                    <button *ngIf="isUpdatingLanguage" (click)="discardLanguageEdit()" class="mat-raised-button mat-button-base lg-i3book-button-white m-2">
                                      {{'create_product.discard_crp' | translate}}
                                    </button>
                                    <button *ngIf="!isUpdatingLanguage" (click)="addLanguageData(); onSubmit();" class="mat-raised-button mat-button-base lg-i3book-button m-2">
                                      {{'create_product.save_crp' | translate}}
                                    </button>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Separador -->
    <div class="row">
        <div class="col">
            <div class="lineSeparator"></div>
        </div>
    </div>
    <hr>
    <!-- Valor -->
    <div class="row d-flex justify-content-evenly">
        <div class="col-5">
            <p class="subtitlesAddBook d-flex justify-content-center">{{'create_product.price_crp' | translate}}</p>
            <p class="smalltitlesAddbok d-flex justify-content-center">{{'create_product.add_price_book_crp' | translate}}</p>
        </div>
        <div class="col-7">
            <div class="row">
                <div class="accordion accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                        <a href="#" class="accordion-button collapsed addBookButton" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree"><i class="bi bi-plus-circle-fill sizeIconAdd"></i><b class="addBookButton"> {{'create_product.add_crp' | translate}}</b></a>
                    </h2>
                    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                        <div class="accordion-body">
                            <div class="row">
                                <table mat-table matSort #priceTableSort="matSort" #priceTable [dataSource]="priceDataSource" class="table mb-4 w-100">
                                  <ng-container matColumnDef="position">
                                    <th mat-header-cell *matHeaderCellDef class="sizeThead">#</th>
                                    <td mat-cell *matCellDef="let element">{{element.index}}</td>
                                  </ng-container>


                                  <ng-container matColumnDef="country">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by country" class="sizeThead">{{'create_product.country_crp' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">{{element.country.countryInfo.name}}</td>
                                  </ng-container>


                                  <ng-container matColumnDef="taxes">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by taxes" class="sizeThead">{{'create_product.tax_crp' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">{{element.country.currency.symbol}} {{element.taxes}}</td>
                                  </ng-container>


                                  <ng-container matColumnDef="amount">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by amount" class="sizeThead">{{'create_product.price_crp' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">{{element.country.currency.symbol}} {{element.amount}}</td>
                                  </ng-container>


                                  <ng-container matColumnDef="totalCost">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by totalCost" class="sizeThead">{{'create_product.total_amount_crp' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">{{element.country.currency.symbol}} {{getTotalCost(element)}}</td>
                                  </ng-container>


                                  <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef class="sizeThead">{{'create_product.actions_crp' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                      <button class="btn" (click)="editElementPrice(element)"><i class="bi bi-pencil-fill colorIconAction"></i></button>
                                      <button class="btn" (click)="removePriceData(element.index); onSubmit();"><i class="bi bi-trash-fill colorIconAction"></i></button>
                                    </td>
                                  </ng-container>

                                  <tr mat-header-row *matHeaderRowDef="priceDisplayedColumns"></tr>
                                  <tr mat-row *matRowDef="let row; columns: priceDisplayedColumns;"></tr>


                                  <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell no-data-centered" colspan="6">No data</td>
                                  </tr>
                                </table>
                              </div>
                              <div [formGroup]="priceForm">
                              <div class="row">
                                  <div class="col mb-2">
                                    <mat-form-field appearance="fill" class="inputSize">
                                      <mat-label>{{'create_product.country_crp' | translate}}</mat-label>
                                      <mat-select formControlName="country">
                                        <mat-option *ngFor="let country of validAvailableCountries" [value]="country">
                                          {{country.countryInfo.name}} - {{country.currency.name}}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col">
                                      <input type="number" step=".00" formControlName="taxes" name="taxes" id="taxes" class="inputSize form-control" placeholder="{{'create_product.tax_crp' | translate}}">
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col">
                                      <input type="number" step=".00" formControlName="amount" name="amount" id="amount" class="inputSize form-control" placeholder="{{'create_product.unit_price_before_tax_crp' | translate}}">
                                  </div>
                              </div>
                              <div class="row">
                                <div class="col">
                                  <button *ngIf="isUpdatingPrice" mat-raised-button (click)="editPriceData1(); onSubmit()" class="mat-raised-button mat-button-base lg-i3book-button m-2">
                                    {{'update_product.update_up' | translate}}
                                  </button>
                                  <button *ngIf="isUpdatingPrice" mat-raised-button (click)="discardPriceEdit()" class="mat-raised-button mat-button-base lg-i3book-button-white m-2">
                                    {{'create_product.discard_crp' | translate}}
                                  </button>
                                  <button *ngIf="!isUpdatingPrice" mat-raised-button (click)="addPriceData(); onSubmit();" class="mat-raised-button mat-button-base lg-i3book-button m-2">
                                    {{'create_product.save_crp' | translate}}
                                  </button>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Separador -->
    <div class="row">
      <div class="col">
          <div class="lineSeparator"></div>
      </div>
  </div>
  <br><br><br>
  </form>
    </ng-container>
</div>
