import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { PaypalConfirmationComponent } from './components/paypal-confirmation/paypal-confirmation.component';
import { PayuConfirmationComponent } from './components/payu-confirmation/payu-confirmation.component';

const routes: Routes = [
  {
    path: 'payment',
    children: [
      { path: 'confirm-paypal-payment', component: PaypalConfirmationComponent},
      { path: 'confirm-payu-payment', component: PayuConfirmationComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentRoutingModule {}
