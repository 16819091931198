import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditBookInformationComponent } from './components/edit-book-information/edit-book-information.component';
import { ViewArchivedBooksTableInformationComponent } from './components/view-archived-books-table-information/view-archived-books-table-information.component';
import {MatTableModule} from '@angular/material/table';
import { BooksRoutingModule } from './books-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../shared/matherial/matherial.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShopCartComponent } from './components/shop-cart/shop-cart.component';
import { BookDetailsInformationComponent } from './components/book-details-information/book-details-information.component';
import { ShopCartBookComponent } from './components/shop-cart-book/shop-cart-book.component';
import { ListShopBooksComponent } from './components/list-shop-book/list-shop-books.component';
import { ViewMyBooksComponentComponent } from './components/view-my-books-component/view-my-books-component.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { EditProductComponent } from './components/edit-product/edit-product.component';
import { PaymentModule } from '../payment/payment.module';
import { ListCategoryComponent } from './components/list-category/list-category.component';




@NgModule({
  declarations: [
    EditBookInformationComponent,
    ListShopBooksComponent,
    ViewArchivedBooksTableInformationComponent,
    ShopCartComponent,
    BookDetailsInformationComponent,
    ShopCartBookComponent,
    ViewMyBooksComponentComponent,
    EditProductComponent,
    ListCategoryComponent
  ],
  imports: [
    NgxPaginationModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    MatTableModule,
    PaymentModule,
    BooksRoutingModule
  ]
})
export class BooksModule { }
