import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { CookieTokenService } from 'src/app/auth/services/cookie-token.service';  
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { environment as env } from 'src/environments/environment';
import { UserRegister } from '../models/user-register.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _httpClient: HttpClient, private _cookieTokenService: CookieTokenService) { }

  getAllUsers() {
    return this._httpClient.get(`${env.url_api}/${env.api_version}/users/list`, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  deleteUser(userId: number) {
    return this._httpClient.delete(`${env.url_api}/${env.api_version}/users/user/${userId}`, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  createUser(user:UserRegister){
    return this._httpClient.post(`${env.url_api}/${env.api_version}/users/register`, user, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  createNewUserFromAdmin(user:UserRegister){
    return this._httpClient.post(`${env.url_api}/${env.api_version}/users/register/admin`, user, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  getUser(userId: number){
    return this._httpClient.get(`${env.url_api}/${env.api_version}/users/user/id/${userId}`, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  updateUserFromAdmin(userProducts: Object){
    // console.log('userService.ts userProducts: ', userProducts, ' - type: ', typeof(userProducts));

    let headers = new HttpHeaders()
    .set('NO-CONTENT-TYPE', 'no-content')
    .set('Accept', 'application/json');

    return this._httpClient.post(`${env.url_api}/${env.api_version}/users/update-user`, userProducts, { observe: 'response' as 'response',headers: headers })
    .pipe(map((response: HttpResponse<any>) => {
      // console.log(response);

      return ResponseHelper.generateCommonResponse(response);
    }));
  }

}
