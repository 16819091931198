import { Component, Output, EventEmitter, LOCALE_ID, ChangeDetectorRef, AfterViewInit, HostListener } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { ShopProduct } from '../../models/shop-product.model';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { ShopProductsService } from '../../services/shop-products.service';
import { CoverImagesHelper } from 'src/app/shared/helpers/cover-images.helper';
import { TranslateService } from '@ngx-translate/core';
import { ShopCartService } from '../../services/shop-cart.service';
import { LoginService } from 'src/app/auth/services/logIn.service';
import { Subscription } from 'rxjs';
import { CategoryProductService } from '../../services/category-product.service';
import { Category } from '../../models/category-product.models';
import { log } from 'console';

@Component({
  selector: 'app-list-shop-books',
  templateUrl: './list-shop-books.component.html',
  styleUrls: ['./list-shop-books.component.scss'],
})

export class ListShopBooksComponent implements AfterViewInit {
  sizeDisplay: string;
  ifThereAreProducts: boolean;
  searchLikeString: string;
  existProducts: boolean = true;
  existFilter: boolean = false;
  currentFilter: string = '';  // Variable para almacenar el filtro actual
  productosFiltrados: any[] = [];  // Lista de productos después del filtro
  selectedCategoryFilter: string = '';
  selectedRangeFilter: string = '';

  private readonly getShopBooksObserver = {
    next: (data: CommonResponse<any>) => { this.getShopBooksNext(data), this.productsCompleteData = data.data },
    error: (error: CommonResponse<any>) => this.getShopBooksError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  private readonly addProductToCartObserver = {
    next: (data: CommonResponse<any>) => this.addProductToCartNext(data),
    error: (error: CommonResponse<any>) => this.addProductToCartError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  private readonly getProductsBySearchedObserver = {
    next: (data: CommonResponse<any>) => this.getProductsBySearchNext(data),
    error: (error: CommonResponse<any>) => this.getProductsBySearchError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  productsData: ShopProduct[];
  productsCompleteData: ShopProduct[];

  @Output() pageChange: EventEmitter<number>;

  images: string[] = [];
  horizontalImages: string[] = []; // Imágenes horizontales
  verticalImages: string[] = []; // Imágenes verticales
  showSmallScreenImages: boolean = false; // Variable para controlar la visualización de las imágenes en pantallas pequeñas

  firstLanguageChange: boolean = true;
  page: number = 1;
  private langChangeSubscription: Subscription;
  constructor(
    private _snacbarService: SnackbarService,
    private _pageLoadingService: PageLoadingService,
    private _shopProductsService: ShopProductsService,
    private _translateService: TranslateService,
    private _shopCartService: ShopCartService,
    private _loginService: LoginService,
    private _shop_productService: ShopProductsService,
    private translateService: TranslateService
  ) {
    this.fillImagesArray();

    this._pageLoadingService.showLoadingGif();

    this._shopProductsService.getShopProducts()
      .subscribe(this.getShopBooksObserver);

    this._shopProductsService.getLikeString().subscribe((data: string) => {
      this.searchLikeString = data;

      this.getLikeString(this.searchLikeString);
    });
  }

  ngAfterViewInit(): void {
    let storedCountryName = localStorage.getItem('countryName');
    console.log(storedCountryName);

    // Suscríbete al evento de cambio de idioma
    this._translateService.onLangChange.subscribe(() => {
      this.fillImagesArray();
    });
  }
  getTranslatedText(key: string): string {
    let storedCountryName = localStorage.getItem('countryName');
    if (storedCountryName && (storedCountryName.toLowerCase() === 'españa')) {
      return this.translateService.instant(key + '_spain');
    } else {
      return this.translateService.instant(key);
    }
  }
  ngOnDestroy(): void {
    // Darse de baja de la suscripción al evento de cambio de idioma
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  getShopBooksNext(data: CommonResponse<any>) {
    this.productsData = data.data;

    // this.productsCompleteData = data.data;
    if (this.productsData.length > 0) {
      this.existProducts = true;
    }
  }

  getSumPriceTotal(amount: number, taxes: number) {
    let suma = Number(amount) + Number(taxes);
    return suma.toFixed(2);
  }

  getShopBooksError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    if (error.statusCode == 404) {
      this.existProducts = false;
      //this._snacbarService.openStandardSnackBar("No se encontraron libros para tu país.");
      this._snacbarService.openStandardSnackBar("getShopBooksError404");
    }
    else {
      //this._snacbarService.openStandardSnackBar("Ha ocurrido un error consultando los libros para tu país.");
      this._snacbarService.openStandardSnackBar('getShopBooksError');
    }
  }

  addProductToCartNext(data: CommonResponse<any>) {
    let storedCountryName = localStorage.getItem('countryName');
    this._pageLoadingService.hideLoadingGif();
    if (storedCountryName && (storedCountryName.toLowerCase() === 'españa')) {
      this._snacbarService.openStandardSnackBar('addProductToCartNext_spain');
    } else {
      this._snacbarService.openStandardSnackBar('addProductToCartNext');
    }
  }

  addProductToCartError(error: CommonResponse<any>) {
    let storedCountryName = localStorage.getItem('countryName');
    this._pageLoadingService.hideLoadingGif();
    if (storedCountryName && (storedCountryName.toLowerCase() === 'españa')) {
      this._snacbarService.openStandardSnackBar('product_exists_on_shopcart_spain');
    } else {
      this._snacbarService.openStandardSnackBar('product_exists_on_shopcart');
    }
  }

  addToCart(productId: number) {
    this._pageLoadingService.showLoadingGif();
    let storedCountryName = localStorage.getItem('countryName');
    if (!this._shopCartService.existProductOnCartLocal(productId)) {
      this._shopCartService.addProductToCart(productId, this.addProductToCartObserver);

      if (!this._loginService.isLogged()) {
        this._pageLoadingService.hideLoadingGif();
        if (storedCountryName && (storedCountryName.toLowerCase() === 'españa')) {
          this._snacbarService.openStandardSnackBar('addToCartSuccessful_spain');
        } else {
          this._snacbarService.openStandardSnackBar('addToCartSuccessful');
        }
      }
    }
    else {
      if (storedCountryName && (storedCountryName.toLowerCase() === 'españa')) {
        this._snacbarService.openStandardSnackBar('addToCartExist_spain');
      } else {
        this._snacbarService.openStandardSnackBar('addToCartExist');

      }
      this._pageLoadingService.hideLoadingGif();
    }
  }

  getBookTitleByLanguage(id: number) {
    const lang = this._translateService.currentLang;
    const product = this.productsData.find((x) => x.productId == id);

    if (product != null && product != undefined) {
      return product.productGeneralInfo.find((x) => x.language.languageIsoCode == lang)?.title;
    } else {
      return 'empty resume';
    }
  }

  getBookResumeByLanguage(id: number) {
    const lang = this._translateService.currentLang;
    const product = this.productsData.find((x) => x.productId == id);

    if (product != null && product != undefined) {
      return product.productGeneralInfo.find((x) => x.language.languageIsoCode == lang)?.resume;
    } else {
      return 'empty resume';
    }
  }

  getCoverImageUrl(imageName: string) {
    return CoverImagesHelper.getCoverImageUrl(imageName);
  }

  getProductsBySearchNext(data: CommonResponse<any>) {

    this.productsData = data.data;
    this._pageLoadingService.hideLoadingGif();

    this.productsCompleteData = data.data;
    if (this.productsData.length == 0) {
      this.existProducts = false;
      //aquí va el codigo para mostrar el mensaje en la tienda cuando no se encuntran productos
    } else {
      this.existProducts = true;

    }
  }

  getProductsBySearchError(error: CommonResponse<any>) {
    this.existProducts = true;

    this._pageLoadingService.hideLoadingGif();

    this._shopProductsService.getShopProducts()
      .subscribe(this.getShopBooksObserver);

    if (error.statusCode == 404) {

      // this._snacbarService.openStandardSnackBar("No se encontraron libros para tu país.");
    }
    else {
      this._snacbarService.openStandardSnackBar("errorQueryingBooks");
    }
  }

  getLikeString(valueLookup: string): void {
    this._pageLoadingService.showLoadingGif();
    this._shopProductsService.getShopProductsSearched(valueLookup)
      .subscribe(this.getProductsBySearchedObserver);
  }

  fillImagesArray() {
    const numImages = 2; // Cantidad de imágenes en el carrusel
    const lang = this._translateService.currentLang; // Obtener el idioma actual
    const basePath = '../../../../assets/img/jpg/'; // Ruta base de las imágenes

    // Lógica para cargar las imágenes horizontales
    this.horizontalImages = [];
    for (let i = 1; i <= numImages; i++) {
      const imagePath = `${basePath}${lang}_banner${i}.jpg`;
      this.horizontalImages.push(imagePath);
    }

    // Lógica para cargar las imágenes verticales
    this.verticalImages = [];
    for (let i = 1; i <= numImages; i++) {
      const imagePath = `${basePath}${lang}_vertical_banner${i}.jpg`;
      this.verticalImages.push(imagePath);
    }

    // Mostrar imágenes horizontales o verticales dependiendo del tamaño de la pantalla
    this.updateImagesBasedOnScreenSize();
  }

  // Método para actualizar las imágenes mostradas basadas en el tamaño de la pantalla
  updateImagesBasedOnScreenSize(): void {
    if (this.showSmallScreenImages) {
      this.images = [...this.verticalImages];
    } else {
      this.images = [...this.horizontalImages];
    }
  }

  // Método para cambiar a las imágenes verticales en pantallas pequeñas
  changeToVerticalImages(): void {
    this.images = [...this.verticalImages];
  }

  // Método para cambiar a las imágenes horizontales en pantallas grandes
  changeToHorizontalImages(): void {
    this.images = [...this.horizontalImages];
  }

  // Detectar cambios en el tamaño de la pantalla
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // Actualizar la variable showSmallScreenImages según el tamaño de la pantalla
    this.showSmallScreenImages = window.innerWidth <= 492;

    // Cambiar las imágenes según el tamaño de la pantalla
    this.updateImagesBasedOnScreenSize();
  }

  filterByText(text: string): void {
    this.currentFilter = text;
    this.selectedCategoryFilter = text;
    const result = this.filterProducts('categories');

    result.length > 0 ? this.existFilter = false : this.existFilter = true;
  }

  filterByAgeRange(range: string): void {
    this.currentFilter = range;
    this.selectedRangeFilter = range;
    const result = this.filterProducts('ages');

    result.length > 0 ? this.existFilter = false : this.existFilter = true;
  }

  filterProducts(type: string): any[] {
    this.productsData = this.productsCompleteData;
    const filteredProducts = this.productsData.filter((product: any) => {

      const categories = product.fieldData.categories;
      const ages = product.fieldData.grades;

      if (categories && categories.length > 0 && type === 'categories') {
        this.selectedRangeFilter = '';
        const firstCategory = categories[0];
        const getCategory = firstCategory.data.map((category: string) =>
          category
        );
        const containsCategory = getCategory.includes(this.currentFilter);
        return containsCategory;
      } else if (ages && ages.length > 0 && type === 'ages') {
        this.selectedCategoryFilter = '';

        if (this.currentFilter === '0 - 3') {
          const getAges = parseInt(ages[0].data, 10);
          const isInRange = getAges >= 0 && getAges <= 3;
          return isInRange;
        } else {
          const rangeFilters = this.currentFilter.split('-');
          const rangeStart = parseInt(rangeFilters[0], 10);
          const rangeEnd = parseInt(rangeFilters[1], 10);

          const firstGrade = ages[0];
          const getAges = parseInt(firstGrade.data, 10);
          const isInRange = getAges >= rangeStart && getAges <= rangeEnd;
          this.existFilter = false;

          return isInRange;
        }
      } else {
        return false;
      }
    });

    const commonResponseTemporal: CommonResponse<any> = {
      statusCode: 200,
      message: 'Success',
      data: filteredProducts,
    };

    this.getShopBooksNext(commonResponseTemporal);

    return filteredProducts;
  }
  deleteFilter() {
    this.currentFilter = '';
    this._shop_productService.searchLikeString(this.currentFilter);
    this.selectedRangeFilter = '';
    this.selectedCategoryFilter = '';
    this.existFilter = false;
  }

}
