<br><br>
<ng-container *ngIf="shopCartBooks.length > 0; else elseTemplate">
  <div class="row mainResponsive">
    <nav class="navbar navbar-expand">
      <div class="container-fluid">
        <div class="navbar-nav">
          <a class="nav-link" [routerLink]="['/book/shop']" routerLinkActive="router-link-active">Inicio |</a>
          <a class="nav-link" href="#">{{ getTranslatedText('store.basket') }}</a>
          <a class="nav-link" href="#">{{'shopping_cart.pay_book' | translate}}</a>
        </div>
      </div>
    </nav>
  </div>
  <div class="container">
    <div class="row">
      <!-- Info libro -->
      <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6 orderMain">
        <div *ngFor="let product of shopCartBooks">
          <div class="row">
            <app-shop-cart-book
              (deleteProductEvent)="deleteProductEventHandler($event)"
              [cartProduct]="product">
            </app-shop-cart-book>
          </div>
          <div class="lineSeparatorBook"></div>
        </div>
      </div>
      <!-- Tu orden -->
      <div class="col-md-5 col-lg-5 col-xl-5 col-xxl-6">
        <div class="orderBook p-3">
          <div class="row">
            <div class="col">
              <p class="orderTitle">{{'shopping_cart.your_order_sc' | translate}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="orderItem">
                <p>{{'shopping_cart.subtotal_sc' | translate}}</p>
                <p>{{'shopping_cart.promotional_discount_sc' | translate}}</p>
                <p>{{'shopping_cart.tax_sc' | translate}}</p>
                <p>{{'shopping_cart.shipping_cost' | translate }}</p>
              </div>
              <p class="purchaseTolta">{{'shopping_cart.total_sc' | translate}}</p>
            </div>
            <div class="col itemTotal">
              <div class="orderItem">
                <p *ngIf="currencySymbol !='€'">{{currencySymbol}} {{getTotalCostWithoutTaxes() | number:'':'es-CO'}}</p>
                <p *ngIf="currencySymbol =='€'">{{getTotalCostWithoutTaxes() | number:'':'es-CO'}} {{currencySymbol}}</p>
                <p *ngIf="currencySymbol !='€'">{{currencySymbol}} 0.0</p>
                <p *ngIf="currencySymbol =='€'">0.0 {{currencySymbol}}</p>
                <p *ngIf="currencySymbol !='€'">{{currencySymbol}} {{getTotalTaxes() | number:'':'es-CO'}}</p>
                <p *ngIf="currencySymbol =='€'">{{getTotalTaxes() | number:'':'es-CO'}} {{currencySymbol}}</p>
                <p *ngIf="currencySymbol !='€'">{{currencySymbol}} {{getShippingCost() | number:'':'es-CO'}}</p>
                <p *ngIf="currencySymbol =='€'">{{getShippingCost() | number:'':'es-CO'}} {{currencySymbol}}</p>
              </div>
              <p class="purchaseTolta">{{currencySymbol}}{{getTotalCostWithTaxes() | number:'':'es-CO'}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col p-4">
              <div class="row warningDescription">
                <div class="col-2 text-center">
                  <i class="bi bi-exclamation-diamond iconWarning p-3"></i>
                </div>
                <div class="col description p-2">
                  <p>
                    {{'shopping_cart.all_rights_reserved_sc' | translate}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <ng-container *ngIf="isLoggedUser(); else elseTemplate">
              <app-payment-methods></app-payment-methods>
            </ng-container>
            <ng-template #elseTemplate>
              <button type="button" class="continueShopping" [routerLink]="['/users/register']"><i class="bi bi-bag-fill"></i>{{'shopping_cart.register' | translate}}</button>
            </ng-template>
          </div>
        </div>
        <div class="row p-3"></div>
      </div>
      <div class="col-1"></div>
    </div>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <div class="row text-center">
    <div class="col">
      <h2>{{ getTranslatedText('shopping_cart.basket_empty_sc') }}</h2>
      <br>
      <button class="sendAddBook" [routerLink]="['/book/shop']">{{'store.go_to_store' | translate}}</button>
    </div>
  </div>
</ng-template>