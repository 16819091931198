const routesWithoutLayout: string [] = [
  '/login',
  '/login/request-qr',
  '/users/register'
];

export class RouteHelper {
  static showLayoutForRoute(url: string) {
    return !routesWithoutLayout.includes(url);
  }
}
