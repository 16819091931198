<div class="d-flex justify-content-end align-items-center headboard">
  <div class="w-auto text-white p-1">
    <div class="d-flex align-items-center justify-content-end">
      <i class="d-inline px-2 bi bi-geo-alt-fill"></i>
      <p class="d-inline w-75 countryText">
        {{'store.shopping' | translate}}
        <b>{{countryName}}</b>
      </p>
    </div>
  </div>
  <div class="text-white p-1" style="width: auto; min-width: 180px;">
    <div class="d-flex align-items-center justify-content-end">
      <i class="d-inline px-2 bi bi-download"></i>
      <p class="d-inline w-75 countryText">
        <a href="{{desktopApplicationDownloadLink}}" class="text-decoration-none downloadDesktopLink"
          download>{{'store.download_i3bookapp' | translate}}</a>
      </p>
    </div>
  </div>
  <ng-container *ngIf="isLoggedUser(); else elseTemplate">
    <div class="w-auto text-white p-1" style="margin: 0 10px;">
      <div class="d-flex justify-content-end">
        <a class="text-decoration-none accountLink dropdown-toggle" role="button" id="userAccountMenuDropdown"
          data-bs-toggle="dropdown" aria-expanded="false">
          <i class="bi bi-person-fill"></i>&nbsp;{{accountName}}
        </a>
        <div class="userOptionsDropdown dropdown-menu" aria-labelledby="userAccountMenuDropdown">
          <a class="myAccountButton fontSizeOption dropdown-item" [routerLink]="['/users/my-account']"
            routerLinkActive="router-link-active">{{'menu.my_account' | translate}}</a>
          <a class="myAccountButton fontSizeOption dropdown-item" (click)="logout()">{{'menu.logout' | translate}}</a>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="btn-group text-white p-1 dropdown" dropdown>
    <button class="btn dropdown-toggle languageMenuItemBase" type="button" data-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false">
      <i class="fa fa-globe" aria-hidden="true"></i>
      {{ siteLanguage }}
    </button>
    <ul dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-animated">
      <li role="menuitem" *ngFor="let language of languageList">
        <a class="dropdown-item text-white" (click)="changeSiteLanguage(language.code)">
          {{ language.label }}
        </a>
      </li>
    </ul>
  </div>

  <ng-template #elseTemplate>
    <div class="px-2">
      <a [routerLink]="['/login']" class="text-decoration-none accountLink">
        <i class="bi bi-person-fill"></i>&nbsp;
        <span>{{'store.enter' | translate}}</span>
      </a>
    </div>
    <div class="px-2">
      <a [routerLink]="['/users/register']" class="text-decoration-none accountLink">
        <i class="bi bi bi-pencil-fill"></i>&nbsp;
        <span>{{'store.register' | translate}}</span>
      </a>
    </div>
  </ng-template>
</div>
<div class="row align-items-center">
  <div class="col-lg-6 col-12 pb-responsive-1">
    <div class="row align-items-center">
      <div [ngClass]="{'col-sm-3': countryName !== 'Colombia', 'col-8': countryName !== 'Colombia', 'col-sm-4': countryName === 'Colombia', 'col-2': countryName === 'Colombia'}"
      class="d-flex text-center align-items-center justify-content-center colnav-img">
        <a [routerLink]="['/book/shop']" routerLinkActive="router-link-active" class="align-items-center justify-content-center d-flex w-100 h-100 border-0">
          <!-- <img src="../../../../assets/img/png/Logo.png" class="imgLogo"> -->
          <ng-container *ngIf=" countryName !== 'Colombia' && countryName !== '' ">
            <img src="../../../../assets/img/png/gridmark.png" class="imgLogo gridmark">
          </ng-container>
          <ng-container *ngIf=" countryName === 'Colombia'">
            <img src="../../../../assets/img/png/logo-new.png" class="imgLogo i3book">
          </ng-container>
        </a>
      </div>
      <div [ngClass]="{'col-sm-8': countryName !== 'Colombia', 'col-10': countryName !== 'Colombia', 'col-sm-7': countryName === 'Colombia', 'col-8': countryName === 'Colombia'}" class="searchBar form-control-col-4 colNav2"
        style="display: inline-flex; padding-top: 0; height: 50%;">
        <i id="iconSearch" class="bi bi-search"></i>
        &nbsp;
        <input type="text" class="inputSearch" [(ngModel)]="searchTerm" (keyup.enter)="searchLikeString()"
          placeholder="{{'store.search_book' | translate}}" aria-label="Search" #searchInput>
        <button class="buttonSearchAndDelete" *ngIf="searchTerm.length > 0" (click)="onCancelSearch()">{{'store.delete'
          | translate}}</button>
        <button class="buttonSearchAndDelete" *ngIf="searchTerm.length <= 0">{{'store.search' | translate}}</button>
        <!-- <input type="text" placeholder="Buscar..." #searchInput> -->
        <!-- <button (click)="searchLikeString(searchInput.value)">
          <i class="fa fa-search"></i>
          Buscar
        </button> -->
      </div>
      <div class="col-1 align-middle sm-screen-menu justify-content-center">
        <button class="btn-reponsive-menu" type="button" data-bs-toggle="collapse" data-bs-target="#mainCollapse"
          aria-expanded="false" aria-controls="mainCollapse">
          <i class="bi bi-list"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="col-lg-1"></div>
  <div class="col-5 bg-screen-menu">
    <div class="row justify-content-end w-100">
      <div class="col-3" *ngFor="let menuItem of menuData let i = index">
        <ul class="nav justify-content-end colNav" style="padding-top: 0;">
          <li class="nav-item-modify" *ngIf="i < limitItemsMenu ; else exceedsLimit">
            <ng-container
              *ngIf="menuItem.children === undefined || menuItem.children.length == 0; else itemHasChildren">
              <a [routerLink]="[menuItem.url]" routerLinkActive="router-link-active"
                routerLinkActive="router-link-active" class="mainMenuItem">
                <i class="{{menuItem.icon}}"></i>&nbsp;
                <span>{{menuItem.titleKey}}</span>
              </a>
            </ng-container>
            <ng-template #itemHasChildren>
              <a class="mainMenuItem dropdown-toggle" id="menuDropDown{{i}}" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                <i class="{{menuItem.icon}}"></i>&nbsp;
                <span>{{menuItem.titleKey | translate}}</span>
              </a>
              <ul class="dropdown-menu" attr.aria-labelledby="menuDropDown{{i}}">
                <li *ngFor="let child of menuItem.children">
                  <a class="mainMenuItemBase dropdown-item" [routerLink]="[child.url]"
                    routerLinkActive="router-link-active">
                    <i class="{{child.icon}}"></i>&nbsp;
                    <span>{{ getMenuItemText(child) }}</span>
                  </a>
                </li>
              </ul>
            </ng-template>
          </li>
          <ng-template #exceedsLimit>
            <li class="nav-item-modify">
              <a [routerLink]="[menuItem.url]" routerLinkActive="router-link-active"
                routerLinkActive="router-link-active" class="mainMenuItem">
                <i class="{{menuItem.icon}}"></i>&nbsp;
                <span>{{menuItem.titleKey | translate}}</span>
              </a>
            </li>
            <div class="downLi"></div>
          </ng-template>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="collapse" id="mainCollapse">
      <hr class="line">
      <div class="row responsive-main-menu">
        <div class="headboard-responsive">
          <div class="d-flex pt-3 align-items-center justify-content-center">
            <p class="d-inline w-75 countryText-responsive">
              <i class="d-inline px-2 bi bi-geo-alt-fill"></i>
              <span>{{'store.shopping' | translate}} </span>
              <b>{{countryName}}</b>
            </p>
          </div>
          <ng-container *ngIf="isLoggedUser(); else elseTemplateResponsive">
            <div class="d-flex w-100 text-white p-1 align-items-center justify-content-center">
              <div class="d-flex justify-content-end">
                <a class="text-decoration-none accountLink-responsive dropdown-toggle" role="button"
                  id="userAccountMenuDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-person-fill"></i>&nbsp;{{accountName}}
                </a>
                <div class="userOptionsDropdown dropdown-menu" aria-labelledby="userAccountMenuDropdown">
                  <a class="myAccountButton fontSizeOption dropdown-item" [routerLink]="['/users/my-account']"
                    routerLinkActive="router-link-active">{{'menu.my_account' | translate}}</a>
                  <a class="myAccountButton fontSizeOption dropdown-item" (click)="logout()">{{'menu.logout' |
                    translate}}</a>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #elseTemplateResponsive>
            <div class="d-flex pt-2 align-items-center justify-content-center">
              <a [routerLink]="['/login']" class="text-decoration-none accountLink-responsive">
                <i class="bi bi-person-fill"></i>&nbsp;
                <span>{{'store.enter' | translate}}</span>
              </a>
            </div>
            <div class="d-flex pt-2 align-items-center justify-content-center">
              <a [routerLink]="['/users/register']" class="text-decoration-none accountLink-responsive">
                <i class="bi bi bi-pencil-fill"></i>&nbsp;
                <span>{{'store.register' | translate}}</span>
              </a>
            </div>
          </ng-template>
          <div class="d-flex pt-2 align-items-center justify-content-center w-100">
            <div class="btn-group text-white p-1 dropdown" dropdown>
              <button class="btn dropdown-toggle languageMenuItemBase" type="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-globe" aria-hidden="true"></i>
                {{ siteLanguage }}
              </button>
              <ul dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-animated">
                <li role="menuitem" *ngFor="let language of languageList">
                  <a class="dropdown-item text-white" (click)="changeSiteLanguage(language.code)">
                    {{ language.label }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="line-responsive-country justify-content-center">
            <hr>
          </div>
        </div>
        <div class="col-12" *ngFor="let menuItem of menuData let i = index">
          <ul class="nav justify-content-start">
            <li class="responsive-nav-item">
              <ng-container
                *ngIf="menuItem.children === undefined || menuItem.children.length == 0; else itemHasChildren">
                <a [routerLink]="[menuItem.url]" routerLinkActive="router-link-active"
                  routerLinkActive="router-link-active" class="mainMenuItem"><i
                    class="{{menuItem.icon}}"></i>&nbsp;{{menuItem.titleKey}}<i class="bi bi-caret-down-fill"></i></a>
              </ng-container>
              <ng-template #itemHasChildren>
                <a class="mainMenuItem" data-bs-toggle="collapse" href="#collapseSubMenu{{i}}" role="button"
                  aria-expanded="false" aria-controls="collapseExample">
                  <i class="{{menuItem.icon}}"></i>&nbsp;{{menuItem.titleKey | translate}}&nbsp;<i
                    class="bi bi-caret-down-fill"></i>
                </a>
                <div class="collapse" id="collapseSubMenu{{i}}">
                  <ul class="sub-menu">
                    <li *ngFor="let child of menuItem.children">
                      <a class="mainMenuItemBase dropdown-item" [routerLink]="[child.url]"
                        routerLinkActive="router-link-active">
                        <i class="{{child.icon}}"></i>&nbsp;{{child.titleKey | translate}}
                      </a>
                    </li>
                  </ul>
                </div>
              </ng-template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<hr class="line w-100" style="margin-top: 0 !important;">