import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/auth/services/logIn.service';
import { MenuItem, menuItems } from '../../models/menu-items.model';
import { LoggedUser } from 'src/app/auth/models/user.auth';
import { CountryService } from 'src/app/countries/services/country.service';
import { CommonResponse } from '../../models/reponse.model';
import { PageLoadingService } from '../../services/page-loading.service';
import { UserCountryInfo } from 'src/app/countries/models/user-country-info.model';
import { environment as env } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ShopProductsService } from 'src/app/books/services/shop-products.service';
import { ShopProduct } from 'src/app/books/models/shop-product.model';
import { SnackbarService } from '../../services/snackbar.service';
import { Observable, Subject, catchError, concatMap, from, map, of, switchMap, tap, throwError } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  data : any
  limitItemsMenu: number = 5;
  menuData: MenuItem[] = [];
  accountName: string;
  userCountryInfo: UserCountryInfo;
  desktopApplicationDownloadLink: any;
  searchTerm: string = '';
  siteLanguage: string;
  selectedLanguage: undefined | string;
  countryName: string = '';
  sessionLanguageKey: string = "selectedLanguage";
  languageList = [
    { code: 'en', label: 'English' },
    { code: 'es', label: 'Español' },
  ];

  private readonly getUserCountryObserver = {
    next: (data: CommonResponse<any>) => this.getUserCountryNext(data),
    error: (error: CommonResponse<any>) => this.getUserCountryError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  private readonly getUserCountryObserverName = {
    next: (data: CommonResponse<any>) => this.getUserCountryNextName(data),
    error: (error: CommonResponse<any>) => this.getUserCountryErrorName(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };


  productsData: ShopProduct[];

  constructor(private _loginService: LoginService,
    private _router: Router,
    private _countryService: CountryService,
    private _pageLoadingService: PageLoadingService,
    private _translateService: TranslateService,
    private _shop_productService: ShopProductsService,
    private _snacbarService: SnackbarService
  ) {

    this._pageLoadingService.showLoadingGif();

    this.desktopApplicationDownloadLink = env.desktop_app_url;

    const user = this._loginService.getLoggedUser();

    this.menuData = this.generateUserMenu(user);

    if (user) {
      this.accountName = user.email;
    }

    let temp = this._translateService.currentLang;
    switch (temp) {
      case "es":
        this.siteLanguage = "Español";
        break;
      case "en":
        this.siteLanguage = "English";
        break;
      case "us":
        this.siteLanguage = "English";
        break;
      default:
        this.siteLanguage = "Español";
        break;
    }

    this.fetchCountryInfo()
      .pipe(
      )
      .subscribe(this.getUserCountryObserver);
  }

  fetchCountryInfo() {
    const currentUrl = window.location.href;
    //const url = 'colombia.shop.gridmark-club.com';
    const languageCode = this._translateService.currentLang;

    return this._countryService.getCountryInfoByUrl(currentUrl, languageCode).pipe(
      tap((response) => {
        this.data = response.data;
      })
    );
  }
  /**
   * Cambio de idioma de la página
   * @param localeCode código del idioma
   */
  changeSiteLanguage(localeCode: string): void {
    const selectedLanguage = this.languageList
      .find((language) => language.code === localeCode)
      ?.label.toString();

    if (selectedLanguage) {
      this.siteLanguage = selectedLanguage;
      sessionStorage.setItem(this.sessionLanguageKey, localeCode);

      this._translateService.use(localeCode);

      this._translateService.currentLang = localeCode;

      this.fetchCountryInfo()
      .pipe(
      )
      .subscribe(this.getUserCountryObserverName);

    }

  }

  /**
   * Información del país y envio de countryCode al changeSiteLanguage()
   * @param data Trae datos del país como name, code e ip
   */
  getUserCountryNext(data: CommonResponse<any>) {
    this.userCountryInfo = data.data;
    this.countryName = this.getCountryName(this.userCountryInfo);

    // Almacena countryName en el localStorage
    localStorage.setItem('countryName', this.countryName);

    // Notificar al servicio compartido que la información está lista
    this._countryService.setCountryName(this.countryName);

    // líneas para predefinir Ingles cuando el país no es Colombia ni España
    if (!['España', 'Spain', 'Colombia'].includes(this.countryName)) {
      this.changeSiteLanguage('en');
    }
    else {
      this.changeSiteLanguage('es');
    }
  }

  /**
   * Información del país para obtener el nombre en el nuevo idioma seleccionado
   * @param data Trae datos del país como name, code e ip
   */
  getUserCountryNextName(data: CommonResponse<any>) {
    this.userCountryInfo = data.data;
    this.countryName = this.getCountryName(this.userCountryInfo);

    // Almacena countryName en el localStorage
    localStorage.setItem('countryName', this.countryName);

    // Notificar al servicio compartido que la información está lista
    this._countryService.setCountryName(this.countryName);
  }

  getUserCountryError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
  }

  getUserCountryErrorName(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

  }

  private generateUserMenu(user: LoggedUser | null) {
    let menuData: MenuItem[] = [];

    menuItems.forEach(menuItem => {

      if (menuItem.children !== undefined) {
        let filteredChildrenItems = user !== null && user !== undefined ?
          menuItem.children.filter(c => user.permissions.includes(c.permission!) || c.permission == 'all') :
          menuItem.children.filter(c => c.permission == 'all');

        if (filteredChildrenItems.length > 0) {
          menuData.push({
            titleKey: menuItem.titleKey,
            icon: menuItem.icon,
            children: filteredChildrenItems
          });
        }
      }
      else {
        if (user?.permissions.includes(menuItem.permission!) || menuItem.permission == 'all') {
          menuData.push(menuItem);
        }
      }
    });

    return menuData;
  }

  isLoggedUser() {
    return this._loginService.getLoggedUser();
  }

  logout() {
    this._pageLoadingService.showLoadingGif();
    this._loginService.logout()
      .subscribe((response) => {
        this._router.navigate(['/login']);
        this._pageLoadingService.hideLoadingGif();
      });
  }

  searchLikeString(): void {
    // Aquí puedes agregar la lógica de búsqueda utilizando el valor ingresado
    //console.log('Realizando búsqueda de:', valorBusqueda);
    // this.listShopBooksComponent.getLikeString(valueLookup);
    this._shop_productService.searchLikeString(this.searchTerm);

  }

  onCancelSearch() {
    this.searchTerm = '';
    this._shop_productService.searchLikeString(this.searchTerm);
  }

  getCountryName(userCountryInfo: UserCountryInfo) {
    if (userCountryInfo !== null &&
      userCountryInfo !== undefined &&
      userCountryInfo.countryName !== "") {
      return userCountryInfo.countryName;
    }

    return "";
  }

  // Text for spain
  getMenuItemText(child: MenuItem): string {
    let storedCountryName = localStorage.getItem('countryName');
    if (child.titleKey === 'menu.shop_cart' && storedCountryName && (storedCountryName.toLowerCase() === 'españa')) {
      return this._translateService.instant('menu.shop_cart_spain');
    }
    // De lo contrario, usar la traducción normal
    return this._translateService.instant(child.titleKey);
  }
}

