import { Component } from '@angular/core';
import { PaypalPaymentService } from '../../services/paypal-payment.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { Router } from '@angular/router';
import { environment as env } from 'src/environments/environment';
import { LoginService } from 'src/app/auth/services/logIn.service';
import { PayuPaymentService } from '../../services/payu-payment.service';
import { CountryService } from 'src/app/countries/services/country.service';
import { ShopProductsService } from 'src/app/books/services/shop-products.service';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent {

  readonly startPaypalPaymentObserver = {
    next: (data: CommonResponse<any>) => this.startPaypalPaymentNext(data),
    error: (error: CommonResponse<any>) => this.startPaypalPaymentError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly startPayuPaymentObserver = {
    next: (data: CommonResponse<any>) => this.startPayuPaymentNext(data),
    error: (error: CommonResponse<any>) => this.startPayuPaymentError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  countryName: string = '';
  countriesPaypal: string[] = ['España', 'Spain', 'Japón', 'Japan']; 

  constructor(private _paypalService: PaypalPaymentService,
    private _payuService: PayuPaymentService,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
    private _loginService: LoginService,
    private _countryService: CountryService,
    private _shopProductsService: ShopProductsService,) {
      this.getCountry();
    }

  startPaypalPaymentNext(data: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    let openSandbox = window.open(data.data.payment_redirect_link, '_blank', 'popup');
    let baseShopUrl = `${env.payment.paypal.base_payment_route}/book/shop`;

    let timer = setInterval(function() {
      if(openSandbox?.closed) {
        clearInterval(timer);
        window.location.href = baseShopUrl;
      }
    });
  }

  startPaypalPaymentError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar('paymentErrorOccurred');
  }

  startPayuPaymentNext(data: CommonResponse<any>) {
    
    const loggedUser = this._loginService.getLoggedUser();
    const form = document.createElement('form');
    form.method = 'post';
    form.action = env.payment.payu.webcheckout_url;

    const payuFormInputsNames = [
      'merchantId',
      'accountId',
      'description',
      'referenceCode',
      'amount',
      'tax',
      'taxReturnBase',
      'currency',
      'signature',
      'test',
      'buyerEmail',
      'responseUrl',
      'confirmationUrl'
    ];

    const inputType = 'hidden';
    let payuFormInputs:{[key:string] : HTMLInputElement} = {};

    for(let i of payuFormInputsNames) {
      let input = document.createElement('input');
      input.name = i;
      input.type = inputType;
      payuFormInputs[i] = input;
    }
    
    payuFormInputs['merchantId'].value = data.data.merchantId;
    payuFormInputs['accountId'].value = data.data.accountId;
    payuFormInputs['description'].value = env.payment.payu.description;
    payuFormInputs['referenceCode'].value = data.data.paymentInfo.paymentDetailsId;
    payuFormInputs['amount'].value = data.data.paymentInfo.amount;
    payuFormInputs['tax'].value = env.payment.payu.default_tax;
    payuFormInputs['taxReturnBase'].value = env.payment.payu.default_taxReturnBase;
    payuFormInputs['currency'].value = data.data.currency;
    payuFormInputs['signature'].value = data.data.signature;
    payuFormInputs['test'].value = env.payment.payu.test;
    payuFormInputs['responseUrl'].value = env.payment.payu.response_url;
    payuFormInputs['confirmationUrl'].value = `${env.url_api}/${env.api_version}/payment/payu/confirm-payment`;
    console.log(payuFormInputs);

    if(loggedUser !== null && loggedUser !== undefined) {
      payuFormInputs['buyerEmail'].value = loggedUser.email;
    }
    else {
      delete payuFormInputs['buyerEmail'];
    }

    Object.values(payuFormInputs).forEach(i => {
      form.appendChild(i);
    });

    document.getElementsByTagName('body')[0].appendChild(form);

    form.submit();
  }

  startPayuPaymentError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar('paymentErrorOccurred');
  }

  payByPaypal() {
    this._pageLoadingService.showLoadingGif();

    this._paypalService.startPayment()
      .subscribe(this.startPaypalPaymentObserver);
  }

  payByPayU() {
    this._pageLoadingService.showLoadingGif();

    this._payuService.startPayment()
      .subscribe(this.startPayuPaymentObserver);
  }
  
  getCountry(){
   this.countryName = this._countryService.getCountryName();
    
  }

 // Método para verificar si el país es uno de los países de PayPal
  isPaypalCountry(country: string): boolean {
    return this.countriesPaypal.includes(country);
  }

}
