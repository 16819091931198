<div class="row">
  <!-- Footer -->
  <footer class="text-lg-start text-white pageFooter">
    <!-- Section: Links  -->
    <section>
      <div class="container text-center text-md-start mt-5">
        <!-- Grid row -->
        <div class="row mt-3">
          <!-- Grid column -->
          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Content -->
            <div class="footer_i3">
              <!-- <img src="../../../../assets/img/png/logo-white.png" class="footerLogo" alt=""> -->
              <ng-container *ngIf=" countryName === '' ">
                <img src="" class="mb-3 col-9">
              </ng-container>
              <ng-container *ngIf=" countryName !== 'Colombia' && countryName !== '' ">
                <img src="../../../../assets/img/png/gridmark-white.png" class="footerLogo">
              </ng-container>
              <ng-container *ngIf=" countryName === 'Colombia' ">
                <img src="../../../../assets/img/png/logo-white.png" class="footerLogo" alt="">
              </ng-container>
            </div>
            <p class="bookDescription">
              {{'footer.text_footer' | translate}}
            </p>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 classAling">
            <!-- Links -->
            <p class="text-uppercase fw-bold mb-4 footerTitles">
              {{'footer.site_map_ft' | translate}}
            </p>
            <div class="footerSubtitles footerSubtitles2">
              <a class="footer-link" [routerLink]="['/book/shop']"
                routerLinkActive="router-link-active">{{'footer.home_ft' | translate}}</a>
              <!-- <a class="footer-link" [routerLink]="['/book/shop']" routerLinkActive="router-link-active">{{'footer.my_books_ft' | translate}}</a> -->
              <!-- <a class="footer-link" [routerLink]="['/book/shop']" routerLinkActive="router-link-active">{{'footer.my_books_ft' | translate}}</a> -->
              <a class="footer-link" [routerLink]="['/book/shop-cart']"
                routerLinkActive="router-link-active">{{ getTranslatedText('footer.basket_ft') }}</a>
              <a class="footer-link" [routerLink]="['#']" routerLinkActive="router-link-active">{{'footer.my_account_ft'
                | translate}}</a>
            </div>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->
            <p class="text-uppercase fw-bold mb-4 footerTitles">
              {{'footer.customer_service_ft' | translate}}
            </p>
            <div class="footerSubtitles footerSubtitles2">
              <a class="footer-link disabled-link" [routerLink]="['customer-services/terms']" routerLinkActive="router-link-active" routerLinkActive="router-link-active" disabled>
               {{'footer.terms_conditions_ft' | translate}}
              </a>

              <a class="footer-link disabled-link" [routerLink]="['customer-services/policy']" routerLinkActive="router-link-active" routerLinkActive="router-link-active">
              {{'footer.data_processing_ft' | translate}}
              </a>

            </div>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            <!-- Links -->
            <p class="text-uppercase fw-bold mb-4 footerTitles">{{'footer.contact_us_ft' | translate}}</p>
            <div class="footerSubtitles4">
              <p>
                <i class="bi bi-wallet-fill"></i>
                <ng-container *ngIf=" countryName !== 'Colombia' && countryName !== '' ">
                  <a class="footer-link" href="mailto:international@gridmark-club.com"> {{'footer.email_ft_es' | translate}}</a>
                </ng-container>
                <ng-container *ngIf=" countryName === 'Colombia' ">
                  <a class="footer-link" href="mailto:soporte@i-3net.com"> {{'footer.email_ft_co' | translate}}</a>
                </ng-container>
              </p>
              <p>
                <i class="bi bi-phone-fill"></i>
                <ng-container *ngIf=" countryName !== 'Colombia' && countryName !== '' ">
                  <a class="footer-link" href="tel:+34915647138"> 0034-915647138</a>
                </ng-container>
                <ng-container *ngIf=" countryName === 'Colombia' ">
                  <a class="footer-link" href="tel:+3125273757"> 312 527 37 57</a>
                </ng-container>
              </p>
            </div>
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
    </section>
    <!-- Section: Links  -->
  </footer>
  <!-- Footer -->
</div>