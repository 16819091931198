import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PolicyComponent } from './shared/components/footer/policy/policy.component';
import { TermsComponent } from './shared/components/footer/terms/terms.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'book/shop',
    pathMatch: 'full'
  },
  {
    path: 'book',
    loadChildren: () => import('./books/books.module').then(m => m.BooksModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'customer-services',
    children: [
      {path: 'policy', component: PolicyComponent},
      {path: 'terms', component: TermsComponent},
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
