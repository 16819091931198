<div class="row">
  <div class="tableContainer">
    <div class="addNew" data-toggle="modal" data-target="#addCategoryModal" (click)="addNewCategory()">
      <svg class="addImg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/></svg>
      {{'category.add_category' | translate}}
    </div>
    <table class="table table-striped col-1">
      <thead>
        <tr>
          <th>{{'category.id' | translate}}</th>
          <th>{{'category.category_name' | translate}}</th>
          <th>{{'category.status' | translate}}</th>
          <th>{{'category.actions' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let category of CategoriesData">
          <td>{{ category.id }}</td>
          <td>{{ category.name | translate }}</td>
          <td [ngClass]="{'text-success': category.isDelete === 0, 'text-danger': category.isDelete === 1}">
            {{ category.isDelete === 0 ? 'Activa' : 'Inactiva' }}
          </td>
          <td>
              <i (click)="editCategory(category.id, category.name, category.isDelete)" class="bi bi-pencil-square"></i>
              <i (click)="showDeleteUserConfirmation(category.name, category.id)" data-toggle="modal" data-target="#modalConfirmation" class="bi bi-trash-fill"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div> 
