import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { PaypalConfirmationComponent } from './components/paypal-confirmation/paypal-confirmation.component';
import { PaymentRoutingModule } from './payment-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { PayuConfirmationComponent } from './components/payu-confirmation/payu-confirmation.component';

@NgModule({
  declarations: [
    PaymentMethodsComponent,
    PaypalConfirmationComponent,
    PayuConfirmationComponent
  ],
  imports: [
    CommonModule,
    PaymentRoutingModule,
    TranslateModule
  ],
  exports: [
    PaymentMethodsComponent
  ]
})
export class PaymentModule { }
