import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { PayuPaymentService } from '../../services/payu-payment.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-payu-confirmation',
  templateUrl: './payu-confirmation.component.html',
  styleUrls: ['./payu-confirmation.component.scss']
})
export class PayuConfirmationComponent {

  paymentId: string;
  payerId: string;
  paymentStatus: string = '';
  processingPayment: boolean = true;
  private readonly _waitSecondsForPayment: number = 5000;
  private _payuIntervalConfirmation: any;
  private _getPaymentstatusSubscription: Subscription;
  private readonly _maxTriesCount = 6;
  private _getPaymentTries = 1;

  readonly confirmPaymentObserver = {
    next: (data: CommonResponse<any>) => this.confirmPaymentNext(data),
    error: (error: CommonResponse<any>) => this.confirmPaymentError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _payuService: PayuPaymentService,
    private _pageLoadingService: PageLoadingService) {

    this._route.queryParams.subscribe(params => {
      this.paymentId = params['referenceCode'];

        this._payuService.getPaymentConfirmation(this.paymentId)
              .subscribe(this.confirmPaymentObserver);
      });
    }

  confirmPaymentNext(data: CommonResponse<any>) {
    this.paymentStatus = data.data.data.status;

    if(this.paymentStatus === 'Payed') {
      this.completeConfirmationRety();
    }
  }

  confirmPaymentError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this.processingPayment = false;
    this.paymentStatus = '';
  }

  closeTab() {
    this._router.navigate(['/']);
  }
  
  completeConfirmationRety() {
    this.processingPayment = false;
    this._getPaymentstatusSubscription.unsubscribe();
    clearInterval(this._payuIntervalConfirmation);
  }


}
