<div class="row">
  <div class="text-center image" style="margin-bottom:15px">
      <img [src]="getCoverImageUrl(cartProduct.product.productCoverImageName)" alt="" class="imgBook">
  </div>
  <div class="info">
    <!-- TODO -> Implement labels -->
    <!-- <small class="bookGender">Educativo</small> -->
      <p class="bookTitle">{{getBookTitleByLanguage()}}</p>
      <p class="codeIsbn">{{'create_product.interactive_content' | translate}}: {{cartProduct.product.interactiveCode}}</p>
      <p class="codeIsbn">{{'create_product.product_code_crp' | translate}}: {{getProductMarketCode()}}</p>
      <!-- <p class="license"><small class="bookLicense">Licencia del <b>{{getCurrentDate()|date:'dd \'de\' MMMM \'del\' YYYY':'':'es-CO'}}</b> al <b>{{getDueDate()|date:'dd \'de\' MMMM \'del\' YYYY':'':'es-CO'}}</b></small></p> -->
      <p class="license"><small class="bookLicense">{{'shopping_cart.license_from_sc' | translate}} <b>{{getCurrentDate()}}</b> {{'shopping_cart.to_sc' | translate}} <b>{{getDueDate()}}</b></small></p>
      <br>
      <div class="deleteBook">
          <i class="bi bi-trash-fill"></i>
          <a class="deleteBook" (click)="deleteItem()">{{'shopping_cart.remove_product_sc' | translate}}</a>
      </div>
  </div>
  <div class="value">
    <small class="deleteBook">{{'create_product.quantity' | translate}}</small>
    <div class="quantity-container">
      <a class="quantity-btn" (click)="removeOne()"><i class="bi bi-dash"></i></a>
      <input
        disabled
        class="shop-cart-quantity-input"
        type="number"
        tep="1"
        min="1"
        [ngModel]="cartProduct.quantity">
      <a class="quantity-btn" (click)="addOne()"><i class="bi bi-plus"></i></a>
    </div>
    <small class="deleteBook">{{'create_product.unit_price' | translate}}</small>
    <p> <b>{{cartProduct.price.currencySymbol}} {{getUnitPriceAmount() | number:'':'es-CO'}}</b></p>
    <small class="deleteBook">{{'create_product.total_price' | translate}}</small>
    <p> <b>{{cartProduct.price.currencySymbol}} {{getTotalAmount() | number:'':'es-CO'}}</b></p>
  </div>
</div>
<br>